import { useContext, useRef, useState } from "react";
import { mainDomainName } from "./domainName.js";
import { SetMessageBoxInfoContext } from "../pages/BuyArea.js";

const minimumOutput = 2;

export default function CallersCreatorBox({caller_caller}) {
    // let [isOpenCreator, setIsOpenCreator] = useState(false);
    let [isClickedMoreBtn, setIsClickedMoreBtn] = useState(false);

    return (
        <div id="callersCreatorParent">
            {
                // !isOpenCreator ? 
                //     <a href="" className="styledText"
                //         onClick={e => {
                //             e.preventDefault();

                //             setIsOpenCreator(true);
                //         }}
                //     >
                //     I want to invite people to this platform
                //     </a> : !isClickedMoreBtn ? <Creator caller_caller={caller_caller} /> : null
                !isClickedMoreBtn ? <Creator caller_caller={caller_caller} /> : null

            }

            {
                !isClickedMoreBtn ? <button className="moreBtn" onClick={() => setIsClickedMoreBtn(true)}>See the balance</button> : 
                isClickedMoreBtn ? <SeenCallerInfoBox setIsClickedMoreBtn={setIsClickedMoreBtn} /> : null

            }
            
            
        </div>
    )
}

function getCallerCode(codeOrLink) {
    const httpsLink = codeOrLink.startsWith('https://');

    if (!httpsLink) return codeOrLink; // No HTTPS link found

        // Parse the URL
        const url = new URL(codeOrLink);
        // Retrieve the 'ca' value if it exists
        return url.searchParams.get('ca');
}

function SeenCallerInfoBox({setIsClickedMoreBtn}) {
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);
    
    let [login, setLogin] = useState('');
    let [password, setPassword] = useState('');

    let [balance, setBalance] = useState();

    let [isOutputBtnClicked, setIsOutputBtnClicked] = useState(false);

    const cardNumberRef = useRef();
    const cardUsernameRef = useRef();
    // let [outputTextareaVal, setOutputTextareaVal] = useState('');
    const aTagClickPermissionRef = useRef(true);
    return (
        <div className="logInContainer">
            
            {
                !balance && balance !== 0 ? 
                (
                    <>
                        <h4 style={{marginBottom:' 10px'}}>Verify your identity</h4>
                        <input value={login} onInput={e => setLogin(e.target.value)} type="text" placeholder="Your code or link" />
                        <input value={password} onInput={e => setPassword(e.target.value)} type="text" placeholder="Password" />

                        <a style={{fontSize: '10px', alignSelf: 'start', marginTop: '8px'}}  href="" onClick={e => {
                            e.preventDefault();

                            if(!aTagClickPermissionRef.current) return
                            else {
                                aTagClickPermissionRef.current = false;
                                setTimeout(() => {
                                    aTagClickPermissionRef.current = true;
                                }, 3000);
                            }

                            if(!login) return setMessageBoxInfo({
                                messageIconType: 'error',
                                textElement: <span className="message">Add the your code and then click there again.</span>,
                                onclickFunc: () => setMessageBoxInfo(null)
                            });
                            
                            const mainLogin = getCallerCode(login);

                            fetch('/forgotPassword/' + mainLogin + '?type=cali').then(resp => resp.json())
                            .then(result => {
                                if(result.error) return setMessageBoxInfo({
                                    messageIconType: 'error',
                                    textElement: <span className="message">{result.message}</span>,
                                    onclickFunc: () => setMessageBoxInfo(null)
                                });

                                setMessageBoxInfo({
                                    messageIconType: 'sended',
                                    textElement: <span className="message">Password sent to <b>{result.gmailSlice}</b> gmail.</span>,
                                    onclickFunc: () => setMessageBoxInfo(null)
                                });
                            })
                            .catch(err => {
                                setMessageBoxInfo({
                                    messageIconType: 'error',
                                    textElement: <span className="message">A problem occurred, please try again.</span>,
                                    onclickFunc: () => setMessageBoxInfo(null)
                                });
                            })
                        }} >I forgot my password</a>

                        <button className="loginBtn" onClick={() => {
                            const mainLogin = getCallerCode(login);
                            fetch(`/callerLogIn?login_code=${mainLogin}&password=${password}`).then(resp => resp.json())
                            .then(result => {
                                if(result.error) {
                                    setMessageBoxInfo({
                                        messageIconType: 'error',
                                        textElement: <span className="message">WRONG DATA</span>,
                                        onclickFunc: () => setMessageBoxInfo(null)
                                    });
                                }else{
                                    sessionStorage.setItem('callerOutputLogin', mainLogin);
                                    sessionStorage.setItem('password', password);
                                    
                                    setBalance(result.balance);
                                }
                            })
                            .catch(() => setMessageBoxInfo({
                                messageIconType: 'error',
                                textElement: <span className="message">A problem occurred, please try again.</span>,
                                onclickFunc: () => setMessageBoxInfo(null)
                            }))
                        }}>Log in</button>

                        <button className="leftBtn" onClick={() => setIsClickedMoreBtn(false)}><i style={{color: '#F2F9F2', fontSize: '12px'}} className='bx bx-left-arrow-alt'></i></button>
                    </>
                )
                : ( 
                    <>
                        <span style={{color: 'black', fontSize: '11px'}} >{balance / 0.5} 1x1 chaps areas have been purchased through your offer</span>
                        <h5 style={{color: 'black'}} >Your balance is </h5> <h5 className="callerOutputBalance">{balance}$</h5>
                        <span style={{
                            fontSize: '12px',
                            marginTop: '5px',
                            marginBottom: '10px',
                            color: 'orange',
                            fontWeight: '500'
                        }}>Minimum output is <b>{minimumOutput}$</b></span>

                        {
                            isOutputBtnClicked ? (
                                <>
                                    {/* <textarea value={outputTextareaVal} onInput={e => setOutputTextareaVal(e.target.value)} placeholder="pass on your bank card number and your contact details (instagram/facebook/telegram/gmail) so that I can contact you in case of problems" 
                                        style={{height: '200px'}}></textarea> */}
                                    <input ref={cardNumberRef} type="text" placeholder="card number" onInput={e => {
                                        let newvalue = e.target.value.replaceAll(' ', '').split('').map((letter, i) => i % 4 == 0  ? ' ' + letter : letter).join('');
                                        if(newvalue.length > 20 || isNaN(+newvalue[newvalue.length - 1])) newvalue = newvalue.substring(0, newvalue.length - 1);
                                        e.target.value = newvalue
                                    }} />
                                    <input ref={cardUsernameRef} type="text" placeholder="card username" />

                                    <button className="confirmBtn" onClick={() => {

                                        if(!cardUsernameRef.current.value || cardNumberRef.current.value.length < 16){
                                            setMessageBoxInfo({
                                                messageIconType: 'error',
                                                textElement: <span className="message">Please fill in all fields.</span>,
                                                onclickFunc: () => setMessageBoxInfo(null)
                                            });
                                        }else if(balance < minimumOutput) {
                                            setMessageBoxInfo({
                                                messageIconType: 'error',
                                                textElement: <span className="message">Your funds are insufficient to cash out.</span>,
                                                onclickFunc: () => setMessageBoxInfo(null)
                                            });
                                        }else{
                                            const callerLogin_code = sessionStorage.getItem('callerOutputLogin');
                                            const password = sessionStorage.getItem('password');

                                            if(!callerLogin_code || !password) {
                                                setMessageBoxInfo({
                                                    messageIconType: 'error',
                                                    textElement: <span className="message">A problem occurred, please try again.</span>,
                                                    onclickFunc: window.location.reload
                                                });
                                            } else{
                                                fetch('/callerOutput', {
                                                    method: 'post',
                                                    headers: {'content-type' : 'application/json'},
                                                    body: JSON.stringify({
                                                        callerLogin_code, 
                                                        password,
                                                        cardNumber: cardNumberRef.current.value,
                                                        cardUsername: cardUsernameRef.current.value
                                                    })
                                                }).then(resp => resp.json())
                                                .then(result => {
                                                    if(result.error) {
                                                        setMessageBoxInfo({
                                                            messageIconType: 'error',
                                                            textElement: <span className="message">A problem occurred, please try again.</span>,
                                                            onclickFunc: window.location.reload
                                                        });
                                                    }else{
                                                        setMessageBoxInfo({
                                                            messageIconType: 'good',
                                                            textElement: <span className="message">Your transaction was successfully accepted, the amount will be transferred after data verification and you will receive a corresponding message to your gmail address..</span>,
                                                            onclickFunc: window.location.reload
                                                        });
                                                    }
                                                })
                                                .catch(() => setMessageBoxInfo({
                                                    messageIconType: 'error',
                                                    textElement: <span className="message">A problem occurred, please try again.</span>,
                                                    onclickFunc: window.location.reload
                                                }));
                                            }
                                        }
                                    }}>Confirm output</button>

                                    <button className="leftBtn" onClick={() => setIsClickedMoreBtn(false)}><i style={{color: 'black', fontSize: '12px'}} className='bx bx-left-arrow-alt'></i></button>
                                </>    
                            ) :
                            <button style={{
                                background: 'rgb(9, 234, 28)',
                                color: 'white',
                                marginTop: '10px',
                                padding: '5px 0',
                                border:'none',
                                borderRadius: '5px',
                                fontWeight: '600',
                                letterSpacing: '0.8px'
                            }} onClick={() => { 
                                setIsOutputBtnClicked(true)
                            }}>Output</button>
                        }
                       
                    </>
                )
                
            }
        </div>
    )
}

function Creator({caller_caller}) {
    let [codeInfo, setCodeInfo] = useState();

    return (
        <>
            {
                codeInfo ? <ShowCodeInfo caller_caller={caller_caller} codeInfo={codeInfo} cancelCode={() => setCodeInfo(null)} />
                :
                <CodeCreatore setCodeInfo={setCodeInfo} />
            }
        </>
    )
}

function copyText(e) {
    const btnText = e.target.textContent;
    var text = e.target.previousElementSibling.previousElementSibling.textContent.trim();
    
    navigator.clipboard.writeText(text).then(function() {
      // Success message
      e.target.style.color = '#1565C0'
      e.target.textContent = 'Copied';
  
      // Reset button text after a short delay
      setTimeout(function() {
        e.target.textContent = btnText;
        e.target.style.color = ''
      }, 2000); // 1.5 seconds
    }).catch(err => copyText(e));
}

function ShowCodeInfo({caller_caller, codeInfo: {code, email, password}, cancelCode}) { // ca == caller
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);

    let [isDone, setIsDone] = useState(false);
    
    return(
        <div id="showCodeCont">
            <div className="codesBox" style={{marginBottom: '5px'}}>
                <a className='bx bx-question-mark quesMark' href="#codeInfoBox" ></a>

                <span>Can use this code if needed </span>
                <span className="infoText">{code}</span><br/>
                <button className="copyTextBtn" onClick={copyText}>Copy code</button>
            </div><hr/>
            <div  className="codesBox">
            <a className='bx bx-question-mark quesMark' href="#buyersInfoBox"></a>

                <span>You can invite buyers and get <b>8%</b> or <br/> you can invite inviters and get <b>2%</b></span><br/>
                <span className="infoText">{mainDomainName}/mainPage?ca={code}</span><br/>
                <button className="copyTextBtn"  onClick={copyText}>Copy link</button>
            </div><hr/>

            {/* <div  className="codesBox">
                <a className='bx bx-question-mark quesMark' href="#invitersInfoBox" ></a>

                <span>You can invite inviters and get <b>2%</b></span><br/>
                <span className="infoText callerLink">{mainDomainName}/callerToolsPage?secret=HIDkfiK8K3Kl32kY&ca={code}</span> <br/>
                <button className="copyTextBtn"  onClick={copyText}>Copy link</button>
            </div><hr/> */}

            <div className="commandText">Save this data, further withdrawal will be done using these</div>

            <div style={{marginBottom: '5px'}}>
                <span>Your password is </span>
                <span className="infoText"><b>{password}</b></span>
            </div>

            <div style={{marginBottom: '5px'}}>
                <span>Your gmail is </span>
                <span className="infoText"><b>{email}</b></span>
            </div>

            <div className="btnsCont">
                {
                    isDone ? <span className="isReadyText">Ready for use</span> :
                    (   
                        <>
                            <button className="cancelBtn" onClick={cancelCode}>Cancel</button>
                            <button className="confirmBtn" onClick={() => {
                                fetch('/addCaller', {
                                    method: 'post',
                                    headers: {'content-type': 'application/json'},
                                    body: JSON.stringify({ code, email, password, caller_caller })
                                }).then(resp => resp.json())
                                .then(result => {
                                    if(result.error) {
                                        setMessageBoxInfo({
                                            messageIconType: 'error',
                                            textElement: <span className="message">{result.message}</span>,
                                            onclickFunc: window.location.reload
                                        });
                                    }else{
                                        setIsDone(true);
                                    }
                                })
                                .catch(() => setMessageBoxInfo({
                                    messageIconType: 'error',
                                    textElement: <span className="message">A problem occurred, please try again, no code.</span>,
                                    onclickFunc: () => setMessageBoxInfo(null)
                                }))
                            }}>Confirm</button>
                        </>
                    )
                }
            </div>
            
        </div>
    )
}

function CodeCreatore({setCodeInfo}){ 
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);
    
    let [code, setCode] = useState('');
    let [password, setPassword] = useState('');
    let [email, setEmail] = useState('');

    let [messageText, setMessageText] = useState(null);

    const codeQuesRef = useRef();
    const gmailQuesRef = useRef();

    return (
        <div id="callersCreator">

            <h4 style={{marginBottom:' 10px'}}>Create your links</h4>
            
            <span className="message">
                {
                    messageText
                }
            </span>
            

            <div className="item">
                <div ref={codeQuesRef} className="quesMarkParent code">
                    <a className='bx bx-question-mark quesMark' href="#codeInfoBox" ></a> {/*//onClick={e => codeQuesRef.current.classList.toggle('blockText')}*/}
                </div>
                <input spellCheck="false" type="text" value={code} className="code" placeholder="Code" onInput={e => setCode(e.target.value)}/>
                <button className="codeGenerateBtn" style={{
                    background: '#b0ffb0',
                    borderRadius: '5px',
                    border: 'none',
                    padding: '5px 5px'
                }} onClick={() => {
                    const generatedCode = Date.now().toString().split('').sort(() => Math.random() - Math.random()).join('');
                    setCode(generatedCode);
                }}>Generate</button>

            </div>

            <div className="item">
                <div ref={gmailQuesRef} className="quesMarkParent gmail">
                    <i className='bx bx-question-mark quesMark' onClick={e => gmailQuesRef.current.classList.toggle('blockText')} ></i>
                </div>
                <input spellCheck="false" type="email" value={email} className="gmail" placeholder="Your secure gmail" onInput={e => setEmail(e.target.value)}/>
            </div>
            
            <div className="item">
                <input spellCheck="false" type="text" value={password} className="password" placeholder="Create password" onInput={e => setPassword(e.target.value)}/>
            </div>
            
            
            <button className="confirmBtn" onClick={() => {
                const pattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
                if(!(pattern.test(email))) {
                    setMessageText('Submit a valid gmail address.');
                } else if(code.length > 25 || password.length > 25 || email.length > 55) {
                    setMessageText('The field is too big.');
                }else if (password.length < 10) {
                    setMessageText('The password is too short.');
                }
                else if(code){
                    fetch('/isPresentCallerCode?callerCode='+code).then(resp => resp.json())
                    .then(result => {
                        if(result.error) throw 'err';

                        if(!result.isFind) setCodeInfo({
                            code,
                            password,
                            email
                        })
                        else setMessageText('This code is already in use, enter another code.');
                    })
                    .catch(() => {
                        setMessageBoxInfo({
                            messageIconType: 'error',
                            textElement: <span className="message">A problem occurred, please try again.</span>,
                            onclickFunc: window.location.reload
                        })
                    })
                }else{
                    setMessageText('Fill in the blank field.')
                }
            }}>Confirm</button>
            
        </div>
    )
}