import { memo, useContext, useEffect, useRef, useState } from "react";
import { SetMessageBoxInfoContext } from "../pages/BuyArea";

export default memo(function SelPopapAssistent({resizeArea, hidePopap, x, y, maxLength}) {
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);
    // console.log(x, y, maxLength)

    let [size, setSize] = useState(1);

    useEffect(() => {
        setSize(1);
    }, [x, y]);


    let buttons = [];
    for(let i = 0; i < maxLength; i++){
        const val = i + 1;

        const el = (
            <button className={`val${size == val ? ' active' : ''}`} key={val} // val={val}
                onClick={() => {
                    resizeArea(val);
                    setSize(val);
                }}
            >{val}</button>
        );

        buttons[i] = el;
    };

    for(let i = maxLength; i < 20; i++){
        const val = i + 1;

        const el = (
            <button className='val off' key={val} // val={val}
                onClick={() => {
                    setMessageBoxInfo({
                        messageIconType: 'error',
                        textElement: <span className="message">That area is already occupied.</span>,
                        onclickFunc: () => setMessageBoxInfo(null)
                    })
                }}
            >{val}</button>
        );

        buttons[i] = el;
    };

    return (
        <div className="assistent">
                <i className='bx bx-x xBtn' onClick={hidePopap}></i>
                {
                    buttons
                }

                <a className="submitBtnParent" href={`/endBuyAreaTransaction?x=${x}&y=${y}&l=${size}`}>
                    <button className="submitBtn" >Get the area</button>
                </a>

                <span className="count" style={{
                    position: 'absolute',
                    fontSize: '10px',
                    right: '1px',
                    bottom: '-5px',
                    fontWeight: '500',

                }}>{size*size*5 + ' $'}</span>
        </div>
    )
});