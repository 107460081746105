import buyerLinkImg from './buyerLinkImg.png';
import callerLinkImg from './callerLinkImg.png';
import codeImg from './codeImg.png';

export default function Information() {
    return (
        <>
            
            <div className='callersCodeInfoContGroup'>
                <div className='callersCodeInfoCont both' id='buyersInfoBox'>
                    {/* <h4 className='infoBoxTitle'>About the link to invite buyers</h4> */}
                    <h4 className='infoBoxTitle'>Use the link and invite <b>buyers</b></h4>
                    <ol>
                        <li>Create your link and connect with people, telling them about our platform.</li>
                        <li>The interested ones will buy limited spaces on our platform where they can place their profile.</li>
                        <li>Your THE BESTS ZONE account will be credited with an 8% commission on each sale. For example, on a 3x3 square area purchase, you would earn <b>$3.6</b></li>
                    </ol>
                    <img draggable="false" src={buyerLinkImg} />
                </div>
                
                 <div className='callersCodeInfoCont both' id=''> {/*invitersInfoBox */}
                    {/* <h4 className='infoBoxTitle'>About the link to invite inviters</h4> */}
                    <h4 className='infoBoxTitle'>Use the link and invite the <b>inviters</b></h4>
                    <ol>
                        <li>Create your link and invite people who will create their link and invite buyers with their link.</li>
                        <li>The inviters will use your link and create their own link.</li>
                        <li>They will invite buyers using their link.</li>
                        <li>Your THE BESTS ZONE account will be credited with an 2% commission on each sale. For example, on a 3x3 square area purchase, you would earn <b>$0.9</b></li>
                    </ol>
                    <img draggable='false' src={callerLinkImg} />
                </div>
            </div>

            <div className='callersCodeInfoCont' id='codeInfoBox'>
                <h4 className='infoBoxTitle'>About the code</h4>
                <ol>
                    <li>If a buyer clicks the link and does not make a purchase within a few days and receives their space, it is possible that their link will expire. In that case, when they wish to make a purchase, they will need a 'Inviter code' where they can enter your code, and you will receive 8% of the purchase amount. For example, for the purchase of a 3x3 space, your THE BESTS ZONE balance will increase by <b>$3.6</b></li>
                </ol>
                <img draggable='false' src={codeImg} />
            </div>
        </>
    )
}