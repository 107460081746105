import { useEffect, useRef, useState } from "react";
import { getProfileCoordinatesByUsername } from "./ProfilsGrafica";
import './loader.css'
// import sadImage from './buyAreaComponents//boxAssistents/smile-sad.png';

export default function SearchUserBox({onFoundedFunction}) {
    const whenErrorColor = 'red';
    const whenTrueColor = '#A72CAF';
    let [serachBoxInfo, setSearchBoxInfo] = useState({
        username: '',
        isTrue: true,
        prepareForEnlargement: false,
        isNotFounded: false
    });


    useEffect(() => {
        if(serachBoxInfo.prepareForEnlargement) setSearchBoxInfo({
            ...serachBoxInfo,
            prepareForEnlargement: false
        });
    }, [serachBoxInfo.prepareForEnlargement]);

    let isLastNotFoundedRef = useRef(false);
    useEffect(() => {
        if(isLastNotFoundedRef.current) isLastNotFoundedRef.current = false;
    }, [isLastNotFoundedRef.current]);

    useEffect(() => {
        if(serachBoxInfo.isNotFounded) {
            isLastNotFoundedRef.current = true;

            setTimeout(() => {
                setSearchBoxInfo({
                    ...serachBoxInfo,
                    isNotFounded: false
                })
            }, 1000);
        }
    }, [serachBoxInfo.isNotFounded]);

    const inputRef = useRef();

    return (
        <label className={serachBoxInfo.isNotFounded ? 'shock' : ''} style={{borderColor: serachBoxInfo.isTrue ? whenTrueColor : whenErrorColor}} id="searchUserMainBox">
            <input ref={inputRef} className="active" value={serachBoxInfo.username} placeholder="Search by username" type="text" onInput={e => {
                const instagramUsernamePattern = /^([a-zA-Z0-9._]{1,30})?$/;
                const newBoxInfo = {
                    ...serachBoxInfo,
                    username: e.target.value.toLowerCase()
                }
                if(instagramUsernamePattern.test(e.target.value)) {
                    if(!serachBoxInfo.isTrue) {
                        newBoxInfo.isTrue = true;
                        newBoxInfo.prepareForEnlargement = true;
                    }
                }
                else {
                    if(serachBoxInfo.isTrue){
                        newBoxInfo.isTrue = false;
                        newBoxInfo.prepareForEnlargement = true;
                    }
                    
                }
                setSearchBoxInfo(newBoxInfo);
            }} 
            onKeyDown={e => {
                if(e.key === 'Enter') {
                    e.preventDefault();
                    if(!serachBoxInfo.username) return

                    onSubmitFunc();
                }
            }}/>

            <div className="iconsBox">
                {
                    serachBoxInfo.isNotFounded ? (
                        <>
                            <i className='bx bx-sad sadIcon'></i>
                            <span className="notFoundText">Not&nbsp;found</span>
                        </>
                    ) : 
                    serachBoxInfo.isLoading ? (
                        <div className="lds-spinner" style={{color: '#CE2580'}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> 
                    ) :
                    serachBoxInfo.isTrue ? <i style={{
                        // color: serachBoxInfo.boxColor,
                        transition: serachBoxInfo.prepareForEnlargement || serachBoxInfo.username.length == 0 || isLastNotFoundedRef.current ? '0s' : '.2s',
                        fontSize: serachBoxInfo.username.length == 0 ? 'var(--small-size)' : serachBoxInfo.prepareForEnlargement ? '1px' : 'var(--main-size)'
                    }} 
                    className={'bx bx-search-alt-2 '}
                    onClick={e => {
                        if(!serachBoxInfo.username) return
                        onSubmitFunc();
                    }}
                    ></i> : 
                    <i style={{
                        transition: serachBoxInfo.prepareForEnlargement ? '0s' : '.2s',
                        fontSize: serachBoxInfo.prepareForEnlargement ? '1px' : 'var(--main-size)'

                    }} className='bx bx-error-circle whenErrorIcon'></i>
                }
            </div>

        </label>
        
        
    );

    function onSubmitFunc() {
        inputRef.current.readOnly = true;
        getProfileCoordinatesByUsername(serachBoxInfo.username).then(finedProfileInfo => {
            inputRef.current.readOnly = false;
          if(!finedProfileInfo) {
              // if(notFoundedUsernamesRef.current.length >= 1000) notFoundedUsernamesRef.current = []; /////debilotneri hamar
              // notFoundedUsernamesRef.current.push(serachBoxInfo.username);

              return setSearchBoxInfo({
                  ...serachBoxInfo,
                  isNotFounded: true,
                  isLoading: false,
              });
          }
          const {x, y, length} = finedProfileInfo
          //    foundedUsernamesRef.current[serachBoxInfo.username] = {x, y, length}
          setSearchBoxInfo({
              ...serachBoxInfo,
              isLoading: false
          })
          onFoundedFunction({x, y, length})
        });
        
        setSearchBoxInfo({
          ...serachBoxInfo,
          isLoading: true
        });

      }
}

