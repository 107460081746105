import React, { createContext, useState } from "react";
import BuyAreaElement from "../components/BuyAreaElement";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { ReplaceAlertRootElement } from "../components/bigMessageCont/BigMessageCont";
// import ProfilsGrafica from "../components/ProfilsGrafica";
// import ProfilsTrafic from "../components/ProfilsTrafic/ProfilsTrafic";

// Create the context outside the component
export const SetMessageBoxInfoContext = createContext();

export default function BuyArea() {
    let [messageBoxInfo, setMessageBoxInfo] = useState(); // messageIconType, onclickFunc, textElement

    return (
        <>
            <ReplaceAlertRootElement messageBoxInfo={messageBoxInfo} />
            <SetMessageBoxInfoContext.Provider value={setMessageBoxInfo}>
                <Header />
                <h3 style={{
                    textAlign: 'center',
                    color: '#2E14A3',
                    transform: 'translateY(40px)',
                }}>Click on an empty area to mark the area</h3>
                <BuyAreaElement />

                <Footer />
            </SetMessageBoxInfoContext.Provider>
        </>
    )
}