import { memo, useState } from "react";
import Area from "./Area";

export default memo(function AreaExample({size}) {
    

    return (
        <div className="areaExampleParent">
 
            <span className="description">Below is an approximate view of the area to be purchased. the larger your space, the more noticeable it will be, and the largest areas will be added to the <b>"TOP&nbsp;15"</b> list</span>

            <Area size={size} />
        </div>
    )
})