import Footer from "../components/Footer";
import Header from "../components/Header";
// import CallersCreatorBox from "../components/aboutProjectComponents/CallersCreatorBox";
import UpdateProfilePermission_orDeleteProfile from "../components/aboutProjectComponents/updateProfilePermission_orDeleteProfile";

import buyAreaPopap from '../components/aboutProjectComponents/buyAreaPopap.png';
import firstAssistent from '../components/aboutProjectComponents/firstAssistent.png';
import profileMoreInfo from '../components/aboutProjectComponents/profileMoreInfo.png';
import thirdAssistent from '../components/aboutProjectComponents/thirdAssistent.png';
import zoomProfilePic from '../components/aboutProjectComponents/zoomProfilePic.png';
import tops from '../components/aboutProjectComponents/tops.png';
import { memo, useEffect, useRef, useState } from "react";
import { ReplaceAlertRootElement } from "../components/bigMessageCont/BigMessageCont";
import '../aboutEndedTextStyles.css'

const AboutProjectPage_inner = memo(function({setMessageBoxInfo}) {
    const refreashBtnLinkRef = useRef();
    const deleteBtnLinkRef = useRef();

    const aref = useRef(0);
    aref.current++;
    console.log(aref.current, 'oooooo')
    // const {current: [ReplaceAlertRootElement, changeReplaceAlertRootElementFunc]} = useRef(replaceAlertRootElement());

    useEffect(() => {
        if(window.location.hash === '#refreshProfileInfoBtn') refreashBtnLinkRef.current.click()
        else if(window.location.hash === '#deleteProfileInfoBtn') deleteBtnLinkRef.current.click()
    }, []);

    return (
        <>
            {/* {ReplaceAlertRootElement} */}
            <Header />

            <a ref={refreashBtnLinkRef} href="#refreshProfileInfoBtn"></a>
            <a ref={deleteBtnLinkRef} href="#deleteProfileInfoBtn" ></a>
        {/* body {
            font-family: Arial, sans-serif;
            margin: 20px;
            line-height: 1.6;
            color: #555; /* Grayish color for the text 
        } */}

        

        {/* <div id="main"> */}
        <div className="content">
            
            <h2>Project Benefits</h2>
            {/* <div className="p" >Our project can help you expand your influence and increase your follower count. Our website is exclusively designed for influential pages, but even if your page isn't well-known yet, don't worry. With our platform, it will become one of the best.</div> */}
            
            <div className="p" >The main part of the project is a 170 × 170 pixel area composed of 36-pixel sections. You can choose a square area (up to 20 × 20 pixels) and get it. Your Instagram profile picture will be posted there and stay there forever and will be updated when you change your picture from Instagram, ensuring you're always up to date. This means that until all spaces are occupied, you can claim your space and enjoy unlimited promotions, prestige and benefits.</div>
            
            <div className="p" >When people click on your picture, a small window will open showing your profile, and they can directly visit your page from there. Your data is dynamic, so any changes made to your Instagram profile will be updated here automatically.</div>
            <img src={profileMoreInfo} />

            <div className="p" >That's not all. The second key feature of our site is the "top 15 list", where the pages with the largest areas, and thus the most influential, will be displayed.</div>
            <img src={tops} />

            <div className="p" >The quality of the images does not depend on the size of the area used. Even the smallest (1×1) images maintain their high quality.</div>
            <img src={zoomProfilePic} />
            
            <h2>Data Processing</h2>
            <div className="p" >You don't need to worry about your data because we only use it with your permission and do not utilize any confidential information. 
                {/* When you purchase an area, we automatically receive your permission to keep your data updated. If we ever lose access to your data for any reason, such as: */}
            </div>
            {/* <ul>
                <li>Your Instagram account becomes private</li>
                <li>You change your password</li>
                <li>Your account becomes inactive</li>
            </ul> */}
            {/* <div className="p" >We will send a notification to your Gmail. To refresh your data, simply click and provide your permission again. <UpdateProfilePermission_orDeleteProfile type="update" /></div> */}
            
            {/* <h2>If you wish to completely remove your data from our site</h2>
            <div className="p" >To remove your data entirely from our site, please be aware that the space you purchased will no longer be available to you. Click to remove your data. <UpdateProfilePermission_orDeleteProfile type="delete" setMessageBoxInfo={setMessageBoxInfo} /> </div> */}
            
            
        </div>

        <div className="content">
        <h2>How to Purchase an Area</h2>
            <div className="p" ><i>The price for each section is </i></div>
            <ul>
                {/* <li>36px  ×  1$ = 36$ <i style={{color: 'red'}} className='bx bx-x-circle'></i></li> */}
                <li>1px ≈ <b>0.14$</b></li>
                <li>36px  ×  0.14$ ≈ <b>5$</b> <i style={{color: 'rgb(10, 248, 30)'}} className='bx bx-check-circle'></i>&nbsp;&nbsp;&nbsp;<i style={{fontSize: '12px'}}>for the smallest visible space</i></li> 
            </ul>
            
            <div className="p" ><i>To purchase an area</i></div>
            <ol>
                <li>Select "buy area" from the main menu.</li>
                <li>Available and purchased areas will be displayed below.</li>
                <li>Click on the free area you want to claim (click on the top left corner of the desired area).</li>
                <img src={buyAreaPopap} />

                <li>Use the tool that opens to mark your area and click the "Get the area" button.</li>
                <li>On the new page, enter the Instagram username you want to place in the selected area, your gmail address, provide only your address, and choose a password for yourself․</li>
                <img src={firstAssistent} />

                {/* <li>Click "Continue with Facebook" and you will be redirected to Instagram, where you need to click "allow" so we can keep your data updated.</li> */}
                {/* {<li>Click on "Continue with Facebook" and in the window that opens, give us permission to use your data so that we can keep your data up to date</li>} */}
                <li>Click on "Continue" button.</li>
                <li >Finally, confirm the transaction using PayPal. After completing the payment, our admins will review the data and post your page on our platform. If your page size is among the largest, it will also appear in the <b>"TOP 15"</b> list. Just pay with PayPal, or if problems occur, click "Help me buy it" and our specialists will help you complete the purchase.</li>
                <img src={thirdAssistent} />
            </ol>
            
        </div>
        {/* </div> */}
        <div className="p titledText" style={{  margin: '0 20px'}} >Join our project and secure your place in Instagram history, making your profile a permanent part of our unique platform.</div>
            {/* <UpdateProfilePermission_orDeleteProfile type="update" /> */}
            {/* <UpdateProfilePermission_orDeleteProfile type="delete" /> */}
            {/* <CallersCreatorBox /> */}
            <Footer/>
        </>
    )
})

export default function AboutProjectPage() {
    let [messageBoxInfo, setMessageBoxInfo] = useState(); // messageIconType, onclickFunc, textElement

    return (
        <>
            <ReplaceAlertRootElement messageBoxInfo={messageBoxInfo} />
            <AboutProjectPage_inner setMessageBoxInfo={setMessageBoxInfo} />
        </>
    )
}
