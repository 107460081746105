import {memo, useCallback, useMemo, useRef} from 'react';
// import canvasBg from '../canvasbg10x.png';
// import canvasBg from '../170-10xBg.png'
import React, { useEffect, useState } from 'react';
import ProfileMoreInfo from './ProfileMoreInfo';
import CanvasToSVG from './CanvasToSVG';
import LoadWrapper from './LoadWrapper';
// import MyWorker from '../webWorker/workerSetup';
// import Worker from '../webWorker/worker';

// matematic basic

const maxPixelLength = 20;

let exportPixelsDataRef = { current: null };

export function getProfileCoordinatesByUsername(username) {
    const worker = new Worker(new URL('../webWorker/findSearchedProfileWorker', import.meta.url));

    return new Promise((resolve) => {
        worker.onmessage = function(e) {
            const findedProfileObj = e.data;
            worker.terminate();
            resolve(findedProfileObj);
        }
        
        worker.postMessage({
            pixelsData: exportPixelsDataRef.current,
            username
        });
    })

  return exportPixelsDataRef.current[username];
}

export default memo(function ProfilsGrafica({setIsImageLoaded, setFoundedProfileCoordinates, foundedProfileCoordinates, setTraficTop100Info}) {
    let graficaRef = useRef(0);
    graficaRef.current++;
    console.log(graficaRef.current, foundedProfileCoordinates, 'grafica')

    let [pixelsData, setPixelsData] = useState();

    useEffect(() => {
        exportPixelsDataRef.current = pixelsData;
    }, [pixelsData]);

    let [showMoreProfileInfo, setShowMoreProfileInfo] = useState(false); // [x, y, profileInfoObj]
    let [showLoader, setShowLoader] = useState(true);
    
    // const pixelsDataIsNewRef = useRef();   
    const versionRef = useRef();

    let hideMoreProfileInfo = useCallback(() => {
        setShowMoreProfileInfo(false);
    }, []);

    let hideLoader = useCallback(() => {
        setShowLoader(false);
    }, []);

    let setMoreProfileInfo = useCallback(function (e) {
        const [leftCoordinate, topCoordinate] = getEventCoordinatFromCurEl(e);
        
        const x = parseInt((leftCoordinate) / 6);
        const y = parseInt((topCoordinate) / 6);
        
        const originalKey = getOriginalKey(x, y, maxPixelLength, pixelsData);
    const [leftCss, topCss] = getCssPosition(
        document.querySelector('.pixelsCont_parent'),
        leftCoordinate, topCoordinate
    )

        setShowMoreProfileInfo([leftCss, topCss, originalKey ? pixelsData[originalKey] : {}]);
    }, [pixelsData]);

    
                                                                                                                // let [x, setX] = useState(8);
                                                                                                                // useEffect(() => {
                                                                                                                //     // console.log(x);
                                                                                                                // }, []);

                                                                                                                // setTimeout(() => {

                                                                                                                //     // console.log('///'); // 3x
                                                                                                                //     debugger
                                                                                                                //     setX(7)
                                                                                                                // }, 2000);
    useEffect(() => {
        // if(!window.localStorage.getItem('caller')){
            const params = (new URL(window.location)).searchParams;
            const caller = params.get('ca');// caller
            if(caller) {
                fetch(`/isPresentCallerCode?callerCode=`+caller).then(resp => resp.json())
                .then(result => {
                    if(result.error) throw 'err'
                    else if(result.isFind) window.localStorage.setItem('caller', caller);
                    // else setIsTrueCaller_caller(false)
                })
                .catch(window.location.reload)
            }
        // }
    }, []);

    useEffect(() => {

        fetch('/version').then(resp => resp.json())
        .then(result => {
            if(result.error) return window.location.reload();
            versionRef.current = result.version;

            pixelsDataAdderLogic(setPixelsData); // kargavoruma pixels data-n

            
        })
        .catch();

    }, []);

    // }, [pixelsData, bgDrawed]);

    const profilsTraficCalcedRef = useRef(false);                   
    useEffect(() => {

        if(!profilsTraficCalcedRef.current && pixelsData){
            profilsTraficCalcedRef.current = true;

            const sortedTop100Profils_version = localStorage.getItem('sortedTop100Profils_version');
            
            if(sortedTop100Profils_version === versionRef.current){
                let sortedTop100Profils = localStorage.getItem('sortedTop100Profils');
                setTraficTop100Info(JSON.parse(sortedTop100Profils));
                return
            }
            
            const worker = new Worker(new URL('../webWorker/worker3.js', import.meta.url));
            worker.onmessage = function(e) {
                const sortedData = e.data;
                
                setTraficTop100Info(sortedData);
                localStorage.setItem('sortedTop100Profils', JSON.stringify(sortedData));
                localStorage.setItem('sortedTop100Profils_version', versionRef.current);
                
                worker.terminate();
            }
            
            worker.postMessage(pixelsData);

        }

    }, [pixelsData]);

    const searchedProfileSelectorRef = useRef();
    const timeoutIdRef = useRef();
    const whenClearedSetThisRef = useRef();
    useEffect(() => {
        if(foundedProfileCoordinates) {
            const element = searchedProfileSelectorRef.current;
            const {left, top, bottom, right} = element.getBoundingClientRect();
            const parentElement = element.parentElement.parentElement; // Սքրոլվող ծնողը

            const parentRect = parentElement.getBoundingClientRect();

            const pageScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const parentScrollLeft = parentElement.scrollLeft;

            // Տեսանելի սահմանները էջի և ծնողի համար
            const viewTop = pageScrollTop + 50;
            const viewBottom = pageScrollTop + window.innerHeight - 50;
            const viewLeft = parentRect.left + 50;
            const viewRight = parentRect.right - 50;

            // Ստուգել, եթե էլեմենտը տեսանելի է ուղղահայաց և հորիզոնական դիրքերում
            const isInViewVertically = (top >= viewTop && bottom <= viewBottom);
            const isInViewHorizontally = (left >= viewLeft && right <= viewRight);

            let scrollAmountY = 0;
            let scrollAmountX = 0;

            if (!isInViewVertically) {
                if (top < viewTop) {
                    // Էլեմենտը վերևից դուրս է
                    scrollAmountY = top - 50;
                } else if (bottom > viewBottom) {
                    // Էլեմենտը ներքևից դուրս է
                    scrollAmountY = bottom - window.innerHeight + 50;
                }
            }

            if (!isInViewHorizontally) {
                if (left < viewLeft) {
                    // Էլեմենտը ձախից դուրս է
                    scrollAmountX = left - viewLeft + parentScrollLeft;
                } else if (right > viewRight) {
                    // Էլեմենտը աջից դուրս է
                    scrollAmountX = right - viewRight + parentScrollLeft;
                }
            }

            // Սքրոլ անենք էջը ուղղահայաց և ծնողը հորիզոնական
            if (scrollAmountY !== 0) {
                window.scrollBy({
                    top: scrollAmountY,
                    behavior: 'smooth'
                });
            }

            if (scrollAmountX !== 0) {
                parentElement.scrollTo({
                    left: scrollAmountX,
                    behavior: 'smooth'
                });
            }
            // window.scrollBy({
            //     top: 50,
            //     behavior: 'smooth'
            //   });
            // const {innerWidth, innHeight} = window;

            // if(left < 0) dl
            // console.log(left, top)
        }
        if(foundedProfileCoordinates) {
            if(timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = null;
                
                setFoundedProfileCoordinates(null);
                whenClearedSetThisRef.current = foundedProfileCoordinates;
                return
            }

            timeoutIdRef.current = setTimeout(() => {
                timeoutIdRef.current = null
                setFoundedProfileCoordinates(null);
            }, 4000);
        }
    }, [foundedProfileCoordinates]);

    useEffect(() => {

        if(!foundedProfileCoordinates && whenClearedSetThisRef.current) {
            setFoundedProfileCoordinates(whenClearedSetThisRef.current);
            whenClearedSetThisRef.current = null;
        }
    }, [foundedProfileCoordinates]);


    return (
        <div className='pixelsCont_parent original'>
            <div id='pixels_fon_container_moreInfo_container'>
                {
                    showLoader ? <LoadWrapper /> : null
                }
                
                <div id='mainImage_container' onClick={setMoreProfileInfo}> 
                    <CanvasToSVG 
                        // canvasUrl={canvasUrl}
                        // setMoreProfileInfo={}
                        setIsImageLoaded={setIsImageLoaded}
                        hideLoader={hideLoader}
                    />    
                </div>
                
                {
                    showMoreProfileInfo && !foundedProfileCoordinates
                    ? 
                    <ProfileMoreInfo
                    hideMoreProfileInfo={hideMoreProfileInfo}
                    x={showMoreProfileInfo[0]}
                    y={showMoreProfileInfo[1]}
                    profileInfoObj={showMoreProfileInfo[2]}

                    />
                    : null
                }
                {
                    foundedProfileCoordinates ? (
                        // <div id="searchedProfileSelector" ref={searchedProfileSelectorRef} style={{
                            // animation: `topBottom45deg1 4s linear 1 alternate,
                            //             topBottom45deg3 1s linear infinite alternate 4s`,
                            // borderTopLeftRadius: '50px', 
                            // borderBottomRightRadius: '50px', 
                            // transform: 'rotate(-45deg)',
                            // background: 'linear-gradient(45deg, #F48549, #F3293E, #CE2580 52%, transparent 48%)',
                            // width: '8px', 
                            // height: '8px',
                            // position: 'absolute',
                            // top: ((4 + foundedProfileCoordinates.y * 6 - 8) - (8 * Math.sqrt(2) - 8) / 2) + 'px',
                            // left: 4 + foundedProfileCoordinates.x * 6 + ((foundedProfileCoordinates.length * 6 - 8) / 2) +  'px'
                        // }}>

                        // </div>
                        <i id="searchedProfileSelector" class='bx bx-chevrons-down' ref={searchedProfileSelectorRef} style={{
                            animation: `topBottom45deg1 4s linear 1 alternate,
                                        topBottom45deg3 1s linear infinite alternate 4s`,
                            // opacity: '1',
                            // borderTopLeftRadius: '50px', 
                            // borderBottomRightRadius: '50px', 
                            // transform: 'rotate(-45deg)',
                            top: (4 + foundedProfileCoordinates.y * 6 - 15) + 'px',
                            left: 4 + foundedProfileCoordinates.x * 6 + ((foundedProfileCoordinates.length * 6 - 15) / 2) +  'px'
                        }}></i>
                    ) : null
                }
            </div>
        </div>
    );

});

// function getElementOffsetLeft(element) {
//     var offsetLeft = 0;
//     while (element) {
//         offsetLeft += element.offsetLeft - element.scrollLeft + element.clientLeft;
//         element = element.offsetParent;
//     }
//     return offsetLeft;
// }

export function pixelsDataAdderLogic(setPixelsData) { // default obj zut vor nerqevum error chta
    // const pixelsData_version = localStorage.getItem('pixelsData_version');

    // if(pixelsData_version != version) { // ays hamakargchi tvyalnery, ev irakan tvyalnery
        fetch('/pixelsData').then(resp => resp.json())
        .then(data => {
            if(data.error) return window.location.reload();

            const worker = new Worker(new URL('../webWorker/worker2.js', import.meta.url));
            worker.onmessage = function(e) {
                const pixelDataObj = e.data;
                
                        // localStorage.setItem('pixelsData', JSON.stringify(pixelDataObj));
                        // localStorage.setItem('pixelsData_version', version);

                setPixelsData(pixelDataObj);
            }
            
            worker.postMessage(data.dataArr);

            // pixelsDataIsNewRef.current = true;
            setPixelsData(data.dataArr);
        })
        .catch(err => window.location.reload());
    // }else{
    //     pixelsDataIsNewRef.current = false;
    //     setPixelsData(JSON.parse(localStorage.getItem('pixelsData')));
    // }
}

export function getEventCoordinatFromCurEl(e){
    const boundingClient = e.currentTarget.getBoundingClientRect();
    
    const containerOffsetTop = boundingClient.top + (window.scrollY || window.pageYOffset);
    const containerOffsetLeft = boundingClient.left + (window.scrollX || window.pageXOffset);

    // console.log(boundingClient.top);

    const leftCoordinate = e.pageX - containerOffsetLeft + e.currentTarget.scrollLeft - 4;
    const topCoordinate = e.pageY - containerOffsetTop - 4;

    return [leftCoordinate, topCoordinate]
}

export function getCssPosition(scrolledEllement, leftCoordinate, topCoordinate){
    const scrollLeft = scrolledEllement.scrollLeft;
    const innerWidth = window.innerWidth;
    const offset = leftCoordinate - 105;
    const maxScroll = innerWidth + scrollLeft - 235; //210 - 27

    const leftCss = Math.min(Math.max(scrollLeft, offset), maxScroll);
    // const leftCss = offset < scrollLeft ? scrollLeft : (maxScroll) < (offset) ? (maxScroll) : offset;

    // console.log(topCoordinate, topCoordMinus < Math.max(window.scrollY - 100, 0))
    const topCoordMinus = topCoordinate + 5.8; 
    
    let topCss;
    topCss = topCoordMinus + 50 
    // topCss = topCoordMinus < Math.max(window.scrollY - 100, 0) ? topCoordinate + 35 : topCoordMinus + 35;
    return [leftCss, topCss]
}

export function getOriginalKey(x, y, maxPixelLength, pixelsData) {
    if(x > 169) x = 169
    else if(x < 0) x = 0;

    if(y > 169) y = 169
    else if(y < 0) y = 0;
// console.log(x, y);
    if(!pixelsData) return null

    mainLoop: for(let i = 0; i < maxPixelLength; i++) {
        for(let j = 0; j < maxPixelLength; j++) {
            const thisAdvX = (x - i);
            const thisAdvY = (y - j);
            if(thisAdvY < 0) break;
            if(thisAdvX < 0) continue mainLoop;

            const key = thisAdvX + '.' + thisAdvY;
            if(pixelsData[key]){
                const {length} = pixelsData[key];

                if(i < length && j < length){
                    return key
                }
            }
        }
    }
    return null
}