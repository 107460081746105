import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";

export default function BigMessageCont({onclickFunc, messageIconElement, textElement, btnText, mainBtnColorName}) {
    document.body.style.overflow = 'hidden';

    return (
        <div className="bigMessageCont">
            <div className="innerCont">
                {
                    messageIconElement || <i className='bx bx-check-circle messageIcon'></i>
                }
                
                {
                    textElement || <span className="message">Our specialists will contact you soon through your Instagram page.</span>
                }

                <button className={"beautifulBtn " + mainBtnColorName || ''} onClick={() => {
                    document.body.style.overflow = 'visible';
                    onclickFunc();
                }}>{btnText || "Ok"}</button>
            </div>
        </div>
    )
}


export function ReplaceAlertRootElement({messageBoxInfo}) {
    if(messageBoxInfo) document.body.style.overflow = 'hidden';

    return (
        <>
            {
                messageBoxInfo ? (
                    <div className="bigMessageCont">
                        <div className="innerCont">
                            {
                                messageBoxInfo.messageIconType == 'error' ? <i style={{color: 'red'}} className='bx bx-error-circle messageIcon'></i> : 
                                messageBoxInfo.messageIconType == 'good'  ? <i className='bx bx-check-circle messageIcon'></i> :
                                messageBoxInfo.messageIconType == 'sended'? <i style={{color: '#ffea00'}} className='bx bx-mail-send messageIcon'></i> :
                                null
                            }
                            
                            {
                               messageBoxInfo.textElement
                            }
            
                            <button className={"beautifulBtn cacaca"} onClick={() => {
                                document.body.style.overflow = 'visible';
                                messageBoxInfo.onclickFunc();
                            }}>{"Ok"}</button>
                        </div>
                    </div>
                ) : null
            }
        </>
    )
}
