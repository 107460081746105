import { memo, useCallback, useEffect, useState } from "react";
import Header from "../components/Header";
import ProfilsGrafica from "../components/ProfilsGrafica";
import ProfilsTrafic from "../components/ProfilsTrafic/ProfilsTrafic";
import Footer from "../components/Footer";
import SubscribeMainCont from "../components/subscribeMyPlatform/SubscribeMainCont";
import SearchUserBox from "../components/SearchUserBox";
import '../aboutEndedTextStyles.css'

const ProfilsTraficAssistent = memo(function({traficTop100Info}) {
    return (
        <>
            {
                traficTop100Info ? <ProfilsTrafic traficTop100Info={traficTop100Info}/> : null
            }
        </>
    )
});

const SearchUserBoxAssistent = memo(function({isImageLoaded, setFoundedProfileCoordinates}) {
    return (
        <>
            {
                isImageLoaded ? <SearchUserBox onFoundedFunction={setFoundedProfileCoordinates} /> : null
            }
        </>
    )
});
export default function MainPage(){
    let [traficTop100Info, setTraficTop100Info] = useState();
    // console.log(traficTop100Info);

    // useCallback((top100Arr) => {
    //     setTraficTop100Info(top100Arr)
    // }, []);
    let [foundedProfileCoordinates, setFoundedProfileCoordinates] = useState();
    let [isImageLoaded, setIsImageLoaded] = useState();
    return(
        
        <>
            
            <Header
            // actionCreator={actionCreator}
            />
            <MainTitle />

            <SearchUserBoxAssistent isImageLoaded={isImageLoaded} setFoundedProfileCoordinates={setFoundedProfileCoordinates} />
            

            {/* <div> */}
            
                <ProfilsGrafica setIsImageLoaded={setIsImageLoaded} setFoundedProfileCoordinates={setFoundedProfileCoordinates} foundedProfileCoordinates={foundedProfileCoordinates} setTraficTop100Info={setTraficTop100Info} />
            {/* </div> */}
            <SubscribeMainCont />

            <ProfilsTraficAssistent traficTop100Info={traficTop100Info} />
            {/* {
                traficTop100Info ? <ProfilsTrafic traficTop100Info={traficTop100Info}/> : null
            } */}
            
            <Footer />
        </>
    )
}


function MainTitle() {
    let [text, setText] = useState('');

    useEffect(() => {
        fetch('/mainPageTitle').then(resp => resp.json())
        .then(result => {
            if(!result.error) setText(result.title_text);
        })
        .catch()
    }, [])
    return (
        <span id="mainPageTitle" className="titledText" >{text}</span>
    )
}