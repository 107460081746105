import { memo, useCallback, useEffect, useRef, useState } from "react";
import mainLogotip from '../mainLogoLetters.png';
import { directorInstagram } from "./domainName";

let ItemsList = function({items}) {

    return (
        <div className="itemsList">
            {items}
        </div>
    );
};

export default memo(function Header() {
    const params = (new URL(window.location)).searchParams;

    // let headerRef = useRef(0);
    // headerRef.current++;
    // console.log(headerRef.current, 'header');

    const [items, setItems] = useState({
        inPage: [
            <a key={'buy area'} href="/buyArea"
                onClick={(e) => {
                    // actionCreator('buy area');
                }}
            >Buy area</a>,
            <a key={'contact'} href={directorInstagram}
                onClick={() => {
                    // actionCreator('contact');
                }}
            >Contact me</a>,
            <a key={'about'} href="/aboutProject"
                onClick={() => {
                    // actionCreator(null);
                }}
            >About the project</a>,
            <a key={'callerToolsPage'} href={"/callerToolsPage?ca=" + (params.get('ca') || localStorage.getItem('caller') || 'thebestszone_caller')}
            onClick={() => {
                // actionCreator(null);
            }}
        >Invite people</a>
        ],
        inMeniu: []
    });

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const change = useCallback(() => {
        let itemsInMeniuSize = 0;
        if (window.innerWidth < 319) itemsInMeniuSize = 4
        else if (window.innerWidth < 400) itemsInMeniuSize = 3
        else if (window.innerWidth < 513) itemsInMeniuSize = 2
        else if(window.innerWidth < 555) itemsInMeniuSize = 1

        let val = items.inMeniu.length - itemsInMeniuSize;                      ////////////////// miiiiiii poqr sxall ka  ---->-> el chka

        if (val) {
            const newItemsState = {};
            if (val > 0) { // val hat meniuic ej
                // val = 0 - val;
                newItemsState.inPage = items.inPage.concat(items.inMeniu.slice(0, val));
                newItemsState.inMeniu = items.inMeniu.slice(val);
            } else { // val hat ejic meniu
                val = 0 - val
                newItemsState.inPage = items.inPage.slice(0, -val); 
                newItemsState.inMeniu = items.inPage.slice(-val).concat(items.inMeniu); 
            }
            setItems(newItemsState);
        }
    }, [items]);

    useEffect(change, []);

    useEffect(() => {
        const debouncedChange = debounce(change, 500);
        window.addEventListener('resize', debouncedChange);
        return () => {
            window.removeEventListener('resize', debouncedChange);
        };
    }, [change]);

    let [showItemsList, setShowItemsList] = useState(false);

    return (
        <header>
            <a href="/" className="logoLink">
                <div className="logo">
                    <img draggable="false" src={mainLogotip} alt="logo"/>
                </div>
            </a>

            <div className="itemsContainer">
                {items.inPage}
                {
                    items.inMeniu.length ? 
                    <i className={`${showItemsList ? 'bx bx-x' : 'bx bx-list-ul'} listImage`}
                        onClick={() => setShowItemsList(!showItemsList)}
                    ></i> : null
                }
                {
                    showItemsList ? <ItemsList items={items.inMeniu}/> : null
                }
            </div>
        </header>
    );
})