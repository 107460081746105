import { useContext, useEffect, useState } from "react"
import sadSmile from './smile-sad.png';
import happySmile from './happy.png'
import LoadWrapper from "../../LoadWrapper";
import { SetMessageBoxInfoContext } from "../../../pages/BuyArea";

export default function ThirdAssistent({levelInfo: {x, y, userName, length, token, isVerefied}}) {
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);

    let [thirdInnerInfo, setThirdInnerInfo] = useState();

    useEffect(() => {
        if(isVerefied) return setThirdInnerInfo({ // isAdded, status_code
            isAdded: true
        });
        
        fetch('/t', { // third
            method: 'post',
            headers: {'content-type' : 'application/json'},
            body: JSON.stringify({
                gmail: localStorage.getItem('gmail'),
                password: sessionStorage.getItem('password'),

                token,
                x,
                y,
                length,
                // a: localStorage.getItem('a'),
                // b: localStorage.getItem('b'),
                userName,
                caller: localStorage.getItem('caller')
            })
        }).then(resp => resp.json()) //  t === third
        .then(result => {
            
            // if(result.error) {
            //     return window.location.reload();
            //     // setThirdInnerInfo('dlkfjsal')
            // }
            
            setThirdInnerInfo({ // isAdded, status_code
                ...result
            });
    
                // fetch('/payInfo?id=' + localStorage.getItem('b')).then(resp => resp.json())
                // .then(result => {
                //     if(!result.error) {
    
                //     }else{
    
                //     }
                // })
                // .catch()
        })
        .catch(() => { 
            setMessageBoxInfo({
                messageIconType: 'error',
                textElement: <span className="message">A problem occurred, please try again.</span>,
                onclickFunc: window.location.reload
            });
            // alert('A problem occurred, please try again');
            // window.location.replace('/buyArea');
        }); //() => window.location.reload()
    }, []);

    return (
        <>
            {
                thirdInnerInfo ? 
                (
                    !thirdInnerInfo.isAdded ? <NotAddedElement info={thirdInnerInfo} /> : <IsAddedElement />
                ) : 
                <LoadWrapper />
                
            }
        </>
    )
}


function NotAddedElement({info: {status_code=9}}) {
    if(status_code === 9) {
        // window.localStorage.setItem('secret_code'); // problem-i kody
        return (
            <>
                <img className="smile" src={sadSmile} />
                {/* <span>
                    <div className="warrningMessage">
                        <i className='bx bx-error'></i>
                        <span>ushadrutyun</span>
                    </div>
                </span> */}
                
                <span className="message">
                    <span style={{margin: '0 10px'}}>Failed to get the area, please wait a few minutes and try again, if the problem persists, let us know and we will help you get your area.</span>
                </span>

                <a href="https://www.instagram.com/__v.vardanyan__" className="assistentBtn"><button>Contact me</button></a>
            </>
        )
    }
    else{ // 10
        return(
            <>
                <img className="smile" src={sadSmile} />
                <span className="message">
                    <span style={{margin: '0 10px'}}>The area could not be obtained; it is possible that the area was purchased by someone else at this time, </span>
                    <span style={{background: '#e0ad45', padding: '1px 2px'}}>please&nbsp;refresh&nbsp;the&nbsp;page&nbsp;and&nbsp;try&nbsp;again</span>
                </span>
    
                <a href="https://www.instagram.com/__v.vardanyan__" className="assistentBtn"><button>Contact me</button></a>
            </>
        )
    }
}

function IsAddedElement() {
    return(
        <>
            <img className="smile" src={happySmile} />
            <span style={{margin: '0 10px'}}>
                Congratulations, you have completed the installation.
                We will check your information and soon your page will be added to <b>"THE&nbsp;BESTS&nbsp;ZONE"</b> platform.
            </span>

            <a className="assistentBtn" onClick={e => {
                e.preventDefault();
                
                localStorage.removeItem('a');
                localStorage.removeItem('b');
                localStorage.removeItem('userName');
                localStorage.removeItem('x');
                localStorage.removeItem('y');
                localStorage.removeItem('length');
                
                window.location.replace('/')
            }}
            >
            <button className="beautifulBtn">
            OK
            </button>
            </a>
        </>
    )
}