import { memo, useRef, useState } from "react";
import ProfileCont from "./ProfileCont";

export default function ProfilsTrafic({traficTop100Info}) {  // memo petk chi vorovhetev mi angama kanchvelu
    let [showInfo, setShowInfo] = useState(false);

    const alkdRef = useRef(0);
    alkdRef.current++;
    console.log(alkdRef.current, 'profile trafic')
// console.log(traficTop100Info);
// console.log(traficTop100Info)
    return (
        <>
                
            {
                showInfo  || true ? (
                    <>
                        <div id="profilsTraficCont">
                            {/* <i className='bx bx-x xBtn' onClick={() => setShowInfo(false)}></i> */}

                            <div id="top100Profils">
                                
                                {
                                    traficTop100Info.map((profilInfo, i) => <ProfileCont key={i} i={i+1} profilInfo={profilInfo} />)
                                }
                            </div>
                        </div>
                    </>
                ) : (
                    <button style={{ //    #F48549, #F3293E, #CE2580, #A72CAF, #5641D4
                        cursor: 'pointer',
                        background: 'linear-gradient(45deg, #F48549, #F3293E, #CE2580, #A72CAF, #5641D4)',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        fontSize: '16px',
                    }} 
                    onClick={() => {
                        setShowInfo(true);
                    }}>See the <b>TOP 15</b></button>
                )
            }
        </>
    )
}