import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import canvasBg from '../canvasbg1x.png';
// import canvasBg from '../170-10xBg.png'
import { getCssPosition, getEventCoordinatFromCurEl, getOriginalKey, pixelsDataAdderLogic } from "./ProfilsGrafica";
import SelPixelPopap from "./SelPixelPopap";
import BuyAreaCanvas from "./BuyAreaCanvas";


import pixel1 from '../pixels/pixel1x1 (1).png';
import pixel2 from '../pixels/pixel2x2 (1).png';
import pixel3 from '../pixels/pixel3x3 (1).png';
import pixel4 from '../pixels/pixel4x4 (1).png';
import pixel5 from '../pixels/pixel5x5 (1).png';
import pixel6 from '../pixels/pixel6x6 (1).png';
import pixel7 from '../pixels/pixel7x7 (1).png';
import pixel8 from '../pixels/pixel8x8 (1).png';
import pixel9 from '../pixels/pixel9x9 (1).png';
import pixel10 from '../pixels/pixel10x10 (1).png';
import pixel11 from '../pixels/pixel11x11 (1).png';
import pixel12 from '../pixels/pixel12x12 (1).png';
import pixel13 from '../pixels/pixel13x13 (1).png';
import pixel14 from '../pixels/pixel14x14 (1).png';
import pixel15 from '../pixels/pixel15x15 (1).png';
import pixel16 from '../pixels/pixel16x16 (1).png';
import pixel17 from '../pixels/pixel17x17 (1).png';
import pixel18 from '../pixels/pixel18x18 (1).png';
import pixel19 from '../pixels/pixel19x19 (1).png';
import pixel20 from '../pixels/pixel20x20 (1).png';

export const images = 
[pixel1, pixel2, pixel3, pixel4, pixel5, pixel6, pixel7, pixel8, pixel9, pixel10, pixel11, pixel12, pixel13, pixel14, pixel15, pixel16, pixel17, pixel18, pixel19, pixel20]
.map(src => {
    const image = new Image();
    image.src = src;
    return image;
});



// const image = new Image();
// image.src = canvas20x20bg;

const maxPixelLength = 20;

export default memo(function BuyAreaElement() {
    const canvasRef = useRef();

    let [bgDrawed, setBgDrawed] = useState(false);
    let [pixelsData, setPixelsData] = useState();
    let [popapInfo, setPopapInfo] = useState();

    const ctxRef = useRef();

    let hidePopap = useCallback(() => setPopapInfo(null), []);

    useEffect(() => {
        ctxRef.current = canvasRef.current.getContext('2d');

        
        // ctxRef.current.lineWidth = 0.5;
        // ctxRef.current.strokeStyle = 'black';
        // for(let i = 0; i <= 170; i++){

        //     // Begin the path
        //     ctxRef.current.beginPath();

        //     // Move the starting point of the line
        //     ctxRef.current.moveTo(0, i * 6);

        //     // Draw the line to the new coordinates
        //     ctxRef.current.lineTo(canvasRef.current.width, i * 6);

        //     // Stroke the path to make the line visible
        //     ctxRef.current.stroke();
        // }

        // for(let i = 0; i <= 170; i++){

        //     // Begin the path
        //     ctxRef.current.beginPath();

        //     // Move the starting point of the line
        //     ctxRef.current.moveTo(i * 6, 0);

        //     // Draw the line to the new coordinates
        //     ctxRef.current.lineTo(i * 6, canvasRef.current.width);

        //     // Stroke the path to make the line visible
        //     ctxRef.current.stroke();
        // }

        const image = new Image();
        image.onload = () => {
            ctxRef.current.drawImage(image, 0, 0);
            setBgDrawed(true);
        }
        image.src = canvasBg;
        
        fetch('/version').then(resp => resp.json())
        .then(result => {
            if(result.error) {
                return
            }
            pixelsDataAdderLogic(setPixelsData, {}, result.version);
        })
        .catch();
    }, []);

    useEffect(() => {
        ctxRef.current.fillStyle = 'green';
// console.log(pixelsData)
        if(pixelsData && bgDrawed && !Array.isArray(pixelsData)){
            Object.keys(pixelsData).forEach(key => {
                const [x, y] = key.split('.');
                const length = pixelsData[key].length;

                ctxRef.current.fillRect(x * 6, y * 6, length * 6, length * 6);
            });
            
            // setrectsDrawed(true);
        }
    }, [pixelsData, bgDrawed]);

    let getOnClickInfo = useCallback((e) => {
        const [leftCoordinate, topCoordinate] = getEventCoordinatFromCurEl(e);
    
        const x = parseInt((leftCoordinate) / 6);
        const y = parseInt((topCoordinate) / 6);
        
        const originalKey = getOriginalKey(x, y, maxPixelLength, pixelsData);
        if(originalKey) throw originalKey;

        // ctxRef.current.fillStyle = 'black';
        // stugelu hamara, vor qarakusin qarakusu vra chnkarvi; // max length === 20 i depqum
        let maxLength = maxPixelLength;  // 20
        
        {
            mainLoop: for(let i = 1; i <= 19; i++){
                if(i + x > 169) {
                    maxLength = i;
                    break;
                    // continue;
                }
    
                for(let j = 0; j >= -maxPixelLength + 1; j--){
                    // ctxRef.current.fillRect((x + i) * 6, (y + j) * 6, 6, 6); // cishta
                    if(y + j < 0) break; //////////////////////////// 170
                    const areaKey = x + i + '.' + (y + j);
                    
                    if(pixelsData[areaKey]){
                        const {length} = pixelsData[areaKey];
                        // y koordinat  opshi chapy
                        if((y + j) + (length - 1) >= y){
                            maxLength = i
                            // if(i < maxLength) maxLength = i;// es el imast chka, vorovhetev es pahin maxLengthy klini maxPixelSizei chap ajsinqn amenamec chapy
                            break mainLoop; // vorovhetev ajic dzax enq eteum, aysinqn arajin gtnvacne klini amenamoty
                        }
                    }
                }
            }
            // console.log('ttt', maxLength)
            
            mainLoop: for(let i = 1; i <= 19; i++) {
                if(x + i > 169) continue;
                
                for(let rowCol = 1; rowCol <= i; rowCol++){
                    if(y + rowCol > 169) break mainLoop;
                    
                    const areaKey = x + i + '.' + (y + rowCol);
                    if(pixelsData[areaKey]) {
                        if(maxLength > i) {
                            maxLength = i;
                        }
                        break mainLoop;
                    }
                    // ctxRef.current.fillRect((x + i) * 6, (y + rowCol) * 6, 6, 6); 
                    
                    const areaKey2 = x + rowCol + '.' + (y + i);
                    if(pixelsData[areaKey2]) {
                        if(maxLength > i) {
                            maxLength = i;
                        }
                        break mainLoop;
                    }
                    // ctxRef.current.fillRect((x + rowCol) * 6, (y + i) * 6, 6, 6); 
                }
            }

            mainLoop: for(let j = 1; j < 20; j++) { 
                
                if(j + y > 169) { 
                    if(maxLength > j) maxLength = j;
                    break
                } 

                for(let i = -19; i <= 0; i++) {
                    if(x + i < 0) continue;
                    // if(x + i > 169) { 
                    //     // if(maxLength > i) maxLength = i; // es harcy verevi loopna lucum
                    //     continue mainLoop2;
                    // }
                    // ctxRef.current.fillStyle = 'cyan'
                    // ctxRef.current.fillRect((x + i) * 6, (y + j) * 6, 6, 6); 

                    const areaKey = x + i + '.' + (y + j);
                    if(pixelsData[areaKey]){
                        const {length} = pixelsData[areaKey];

                        if((x + i) + (length - 1) >= x) {
                            
                            if(maxLength > j) {
                                maxLength = j;
                            }
                            break mainLoop;
                        }
                    }
                }
            }
        }

        let [leftCss, topCss] = getCssPosition(
            document.querySelector('.pixelsCont_parent'),
            leftCoordinate, topCoordinate
        );

        topCss = topCoordinate - 125;

        return [leftCss, topCss, x, y, maxLength];
    }, [pixelsData]);


    let previusRectCoordinates = useRef();
    let previusRectSizeRef = useRef(); 

    useEffect(() => {
        if(previusRectCoordinates.current) {
            ctxRef.current.clearRect(...previusRectCoordinates.current, previusRectSizeRef.current * 6, previusRectSizeRef.current * 6); 
            ctxRef.current.drawImage(images[previusRectSizeRef.current - 1], ...previusRectCoordinates.current);
        }

        let x, y;
        if(popapInfo){
            x = popapInfo[2];
            y = popapInfo[3];

            previusRectCoordinates.current = [x * 6, y * 6]; // mianqamic kordinatnern em dnum
            previusRectSizeRef.current = 1;
        }else {
            previusRectCoordinates.current = null;
            return
        };

        // previusRectSizeRef.current = 1;
        ctxRef.current.fillStyle = 'blue';
        ctxRef.current.fillRect(x * 6, y * 6, 6, 6); // 1 * 6, 1 * 6

    }, [popapInfo]);
    
    let x;
    let y;
    if(popapInfo){
        x = popapInfo[2];
        y = popapInfo[3];
    }
    
    return (
        <div className="pixelsCont_parent">
            <div id="buyAreaContainer" >
                {
                    popapInfo && x < 170 && y < 170 && x >= 0 && y >= 0 ? <SelPixelPopap setPreviusRectSizeRef={ newSize => previusRectSizeRef.current = newSize } ctx={ctxRef.current} popapInfo={popapInfo} hidePopap={hidePopap} /> : null
                }
                
                <BuyAreaCanvas canvasRef={canvasRef} getOnClickInfo={getOnClickInfo} setPopapInfo={setPopapInfo} />
            </div>
        </div>
    );
})
