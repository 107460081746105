import { useContext, useState } from 'react';
import BigMessageCont from '../../bigMessageCont/BigMessageCont';
import paypalLogo from './paypalLogo.png';
import { SetMessageBoxInfoContext } from '../../../pages/BuyArea';

export default function SecondAssistent({size, levelInfo}) {
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);

    let [seeBigMessage, setSeeBigMessage] = useState(false);
    return (
        <>
            {
                seeBigMessage ? <BigMessageCont onclickFunc={() => {
                    window.location.replace('/');
                }} /> : null
            }
            
            <span className="payDesc" style={{
                color: '#767575',
                fontSize: '11px',
                marginBottom: '5px'
            }}>Finally, confirm the transaction using PayPal. If you need help, click the "Help me buy it" button, and our admins will assist you in completing the purchase.</span>
            <span className="payDesc">Pay <b>{size * size * 5}$</b> instantly and enjoy your profile progress.</span>
            <span className="payDesc papaleInfo" style={{marginTop: '5px'}}>payment is protected by PayPal</span>

            <form action='/pay' method='post' id='payForm'>

                <input name="userId" defaultValue={localStorage.getItem('userName')} style={{display: 'none'}}/> {/*// userId */}
                <input name="length" defaultValue={localStorage.getItem('length')} style={{display: 'none'}}/>

                <button type="submit" className="pay">
                    <span>buy with</span>
                    <img draggable="false" src={paypalLogo} />
                </button>

                <button type='submit' className='pay' style={{
                    background: 'transparent',
                    border: '1px solid rgb(165, 165, 165)',
                    marginTop: '8px'
                }} 
                onClick={(e) => {
                    e.preventDefault();
                    //////////bayc es karainq stanainq nayev levelInfoIc
                    const x = localStorage.getItem('x');
                    const y = localStorage.getItem('y');
                    const length = localStorage.getItem('length');
                    const userName = localStorage.getItem('userName');
                    const gmail = levelInfo.gmail;
                    const password = levelInfo.password;
                    const caller = levelInfo.caller;

                    if(!x || !y || !length || !userName || !gmail || !password || !caller) {
                        setMessageBoxInfo({
                            messageIconType: 'error',
                            textElement: <span className="message">A problem occurred, please try again.</span>,
                            onclickFunc: () => window.location.replace('/buyArea')
                        });
                    }else{
                        fetch(`/helpBuyArea?x=${x}&y=${y}&l=${length}&u=${userName}&g=${gmail}&p=${password}&c=${caller}`, { method: 'post'} )
                        .then(resp => {
                            if(resp.ok) 
                            setSeeBigMessage(true);
                            else throw 'err';
                        })
                        .catch(() => setMessageBoxInfo({
                            messageIconType: 'error',
                            textElement: <span className="message">A problem occurred, please try again.</span>,
                            onclickFunc: () => setMessageBoxInfo(null)
                        }));
                    }
                }}
                >Help me buy it</button>
            </form>
        </>
    )
}