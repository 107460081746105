// import { useEffect } from "react";
import { createContext, memo, useEffect, useRef, useState } from "react";
import CallersCreatorBox from "../components/CallersCreatorBox";
import Footer from "../components/Footer";
import Header from "../components/Header";
import BigMessageCont, { ReplaceAlertRootElement } from "../components/bigMessageCont/BigMessageCont";
import { mainDomainName } from "../components/domainName";
import LoadWrapper from "../components/LoadWrapper";
import Information from "../components/callersPageElements/Information";
import {SetMessageBoxInfoContext} from './BuyArea.js'


const Inner = memo(function() {
    let [isTrueCaller_caller, setIsTrueCaller_caller] = useState(undefined);

    // const secretRef = useRef();
    const caller_callerRef = useRef();

    useEffect(() => {
        const params = (new URL(window.location)).searchParams;
        // secretRef.current = params.get('secret');

        caller_callerRef.current = params.get('ca');
        if(caller_callerRef.current == 'thebestszone_caller') return setIsTrueCaller_caller(true);
        
        fetch(`/isPresentCallerCode?callerCode=`+caller_callerRef.current).then(resp => resp.json())
        .then(result => {
            if(result.error) throw 'err'
            else if(result.isFind) setIsTrueCaller_caller(true)
            else setIsTrueCaller_caller(false)
        })
        .catch(window.location.reload)
    }, []);

    const sref = useRef(0);
    sref.current++
    console.log(sref.current,'555555555555555')
    return (
        <>
            <h3 style={{
                margin: '25px 5px 10px 5px',
                color: 'rgb(46, 20, 163)',
                textAlign: 'center'
            }}>Build your links, invite people, and earn big money.</h3>
            {  // secretRef.current == 'HIDkfiK8K3Kl32kY'
                isTrueCaller_caller === undefined ? <LoadWrapper /> :
                isTrueCaller_caller ? <CallersCreatorBox caller_caller={caller_callerRef.current} /> :
                <BigMessageCont messageIconElement={<i style={{color: 'red'}} className='bx bx-error-circle messageIcon'></i>} textElement={<span className="message">You have used an invalid link, please use a valid link or use our official link: <a style={{fontSize: '10px'}} href={mainDomainName+'/callerToolsPage?secret=HIDkfiK8K3Kl32kY&ca=thebestszone_caller'}>{mainDomainName+'/callerToolsPage?secret=HIDkfiK8K3Kl32kY&ca=thebestszone_caller'}</a></span>} btnText={'Go to main page'} mainBtnColorName='cacaca' onclickFunc={() => {
                    window.location.assign('/')
                }} />
            }
        
            <Information />
        </>
    )
})

export default function CallerTools() {
    let [messageBoxInfo, setMessageBoxInfo] = useState(); // messageIconType, onclickFunc, textElement

    return (
        <div id="callersPageToolsContainer">
            <ReplaceAlertRootElement messageBoxInfo={messageBoxInfo} />

            <Header />
            <SetMessageBoxInfoContext.Provider value={setMessageBoxInfo}>
                <Inner />
            </SetMessageBoxInfoContext.Provider>
            
            {/* <Information /> */}
           

            <Footer />
        </div>
    )
}

