import { useContext, useEffect, useRef } from "react";
import { mainDomainName } from "../../domainName";
import { SetMessageBoxInfoContext } from "../../../pages/BuyArea";

export default function FirstAssistent({levelInfo: {x, y, length, userName, gmail}}) {
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);

    const usernameRef = useRef();
    const gmailRef = useRef();
    const passwordRef = useRef();
    const callerRef = useRef();

    useEffect(() => {
        // const params = (new URL(window.location)).searchParams;
        // usernameRef.current.value = params.get('userName') || '';
        // gmailRef.current.value = params.get('gmail') || '' ;

        usernameRef.current.value = userName || '';
        gmailRef.current.value = gmail || '';
    }, [])
    return (
        <>
            <input ref={usernameRef} style={{
                textTransform: 'lowercase'
            }} type="text" name="instagramUsername" placeholder="Instagram username"
                
            />

            <input autoComplete="off" ref={gmailRef} type="text" name="gmail" placeholder="Your gmail"
                
            />

            <input autoComplete="off" ref={passwordRef} type="text" name="password" placeholder="Create password"
               
            />

            {
                !localStorage.getItem('caller') ? (
                    <input ref={callerRef} type="text" name="caller" placeholder="Your inviter code"
                        
                    />
                ) : null
            }

            <button type="submit" 
                onClick={e => {
                    const pattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
                    if(!(pattern.test(gmailRef.current.value))) return setMessageBoxInfo({
                        messageIconType: 'error',
                        textElement: <span className="message">Provide a valid gmail address.</span>,
                        onclickFunc: () => setMessageBoxInfo(null)
                    });
                    if(passwordRef.current.value.length < 5) return setMessageBoxInfo({
                        messageIconType: 'error',
                        textElement: <span className="message">Password is too short.</span>,
                        onclickFunc: () => setMessageBoxInfo(null)
                    });

                    if(!localStorage.getItem('caller') && !(callerRef?.current?.value)){
                        return setMessageBoxInfo({
                            messageIconType: 'error',
                            textElement: <span className="message">Fill in the blank field "Your inviter code".</span>,
                            onclickFunc: () => setMessageBoxInfo(null)
                        });
                    }else if(usernameRef.current.value && gmailRef.current.value && passwordRef.current.value) {
                        fetch('/isPresentCallerCode?callerCode=' + (localStorage.getItem('caller') || callerRef.current.value))
                        .then(resp => resp.json())
                        .then(result => {
                            if(result.error) {
                                setMessageBoxInfo({
                                    messageIconType: 'error',
                                    textElement: <span className="message">A problem occurred, please try again.</span>,
                                    onclickFunc: () => setMessageBoxInfo(null)
                                });
                            }else if(!result.isFind) {
                                setMessageBoxInfo({
                                    messageIconType: 'error',
                                    textElement: <span className="message">Enter a valid "inviter code".</span>,
                                    onclickFunc: () => setMessageBoxInfo(null)
                                });
                            }else{
                                // const allowInstagramLink = 
                                // "https://www.facebook.com/v20.0/dialog/oauth?" + 
                                // "client_id=344498931719921&" + 
                                // `redirect_uri=${mainDomainName}/endBuyAreaTransaction&` +
                                // "scope=instagram_basic,pages_show_list,business_management";
        
                                localStorage.setItem('x', x + '');
                                localStorage.setItem('y', y + '');
                                localStorage.setItem('length', length);
                                localStorage.setItem('userName', usernameRef.current.value.toLowerCase());
                                localStorage.setItem('gmail', gmailRef.current.value);
                                sessionStorage.setItem('password', passwordRef.current.value);
                                // localStorage.setItem('password', passwordRef.current.value);// es zut sxal chanelu hamara ev petka erb ognutyun en xndrum secondAssistentIc

                                if(!localStorage.getItem('caller')) localStorage.setItem('caller', callerRef.current.value);
        
                                window.location.replace(`/endBuyAreaTransaction?x=${x}&y=${y}&l=${length}&un=${usernameRef.current.value.toLowerCase()}&g=${gmailRef.current.value}&p=${passwordRef.current.value}`);
                            }
                        })
                        .catch(() => window.location.reload());
                    }
                    else setMessageBoxInfo({
                        messageIconType: 'error',
                        textElement: <span className="message">Fill in the blank field.</span>,
                        onclickFunc: () => setMessageBoxInfo(null)
                    });
                }}
            >Continue</button>
            {/* <i className='bx bxl-facebook-circle' ></i> Continue with Facebook */}
        </>
    )   
}