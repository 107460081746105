
import TitleEffect from "./buyAreaComponents/TitleEffect";
import AreaExample from "./buyAreaComponents/AreaExample";
import FirstAssistent from "./buyAreaComponents/boxAssistents/FirstAssistent";
import SecondAssistent from "./buyAreaComponents/boxAssistents/SecondAssistent";
import ThirdAssistent from "./buyAreaComponents/boxAssistents/ThirdAssistent";

export default function EndBuyTransactionBox({levelInfo}) {
    

    return (
        <>

        <AreaExample size={levelInfo.length} />


        <div id="endBuyTransactionBox"> 

            <TitleEffect level={levelInfo.num} />

            {
                levelInfo.num === 1 ? <FirstAssistent levelInfo={levelInfo} /> :
                levelInfo.num === 2 ? <SecondAssistent levelInfo={levelInfo} size={levelInfo.length} /> : 
                levelInfo.num === 3 ? <ThirdAssistent levelInfo={levelInfo} /> : 
                null
            }
            

            <button className="leftBtn" onClick={e => {
                localStorage.removeItem('a');
                localStorage.removeItem('b');
                localStorage.removeItem('userName');

                const params = (new URL(window.location)).searchParams;

                if(params.get('x') && params.get('y') && params.get('l')){
                    localStorage.removeItem('x');
                    localStorage.removeItem('y');
                    localStorage.removeItem('length');

                    window.location.replace(`/buyArea`);
                }else{
                    const x = localStorage.getItem('x');
                    const y = localStorage.getItem('y');
                    const length = localStorage.getItem('length');
    
                    window.location.replace(`/endBuyAreaTransaction?x=${x}&y=${y}&l=${length}`);
                }
            }}>
                <i className='bx bx-left-arrow-alt'></i>
            </button>
        </div>
        </>
    )
}