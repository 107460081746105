import { useEffect, useState } from "react"

export default function SubscribeMainCont(){
    let [onlineUsers, setOnlineUsers] = useState();
    useEffect(() => {
        function fetchOnlineUsers() {
            fetch('/onlineUsers').then(resp => resp.json())
            .then(result => {
                setOnlineUsers(result.onlineUsers)
            })
            .catch(fetchOnlineUsers)
        }
        fetchOnlineUsers();
    }, [])
    return(
        <>
            {
                onlineUsers ? (
                    <div id='mainSubscribeCont' >
                        <div className="statusCont" >
                            {/* <div>
                                <div className="numCont">
                                    <span className="num subscribersCount">10.054.575.400</span>
                                    <i className='bx bxs-user-check'></i>
                                </div>
                                <div className="text">subscriber</div>
                            </div> */}
                            <div>
                                <div className="numCont">
                                    <span className="num">{onlineUsers}</span>
                                    <span className="onlineICont">
                                    <i className='bx bxs-user-circle online'></i>
                                        {/* <i className='bx bxs-user online'></i> */}
                                    </span>
                                </div>
                                <div className="text">users online</div>
                            </div>
                            {/* <span>10000 subscriber</span> */}
                            {/* <span>154 online user</span> */}
                        </div>
                        {/* <button>SUBSCRIBE</button> */}
                    </div>
                ) : null
            }
        </>
        
    )
}