import { memo, useEffect, useRef, useState } from "react";

export default memo(function CanvasToSVG({setIsImageLoaded, hideLoader}) { // erb chgtni local srotageurm et vaxt nor canvasy kogtagorcenq
    let [imageSrc, setImageSrc] = useState();

    let size = useRef(0);
    size.current++;
    console.log('svgImage', size.current);

    
    useEffect(() => {
      async function fetchData() {
        try {
          const [imageAndImVersion, mainImageResp] = await Promise.all([
            getImageAndImageVersionFromDB('MAINIMAGE'),
            fetch('/mainImageVersion')
          ]);
          const result = await mainImageResp.json();

          // console.log(imageAndImVersion.imageVersion !== result.main_image_version, imageAndImVersion, result)
          if (imageAndImVersion.imageVersion !== result.main_image_version) {
            async function fetchFunc() {
                try {
                    const response = await fetch('/mainImage');
                    if (response.ok) {
                      const blob = await response.blob();
                      // console.log(blob, 5555);
                      await setImageAndImageVersionToDB(blob, 'MAINIMAGE', result.main_image_version);
                      const imageUrl = URL.createObjectURL(blob);
                      setImageSrc(imageUrl);
                    } else {
                      throw new Error('Failed to fetch main image');
                    }
                } catch (error) {
                  console.error('Retrying fetch due to error:', error);
                  fetchFunc();
                }
              }
              fetchFunc();
          } else {
            // console.log(imageAndImVersion.blob, 555)
            const imageUrl = URL.createObjectURL(imageAndImVersion.imageBlob);
            setImageSrc(imageUrl);
          }
      } catch (err) {
        // console.error(err);
        window.location.reload();
      }
    }

    fetchData();
    }, []);
  
    // useEffect(() => {
    //   if(imageSrc) hideLoader();
    // }, [hideLoader, imageSrc]); // es [hand] navsyaki
    
    // Return the SVG container
    return (
      <>
        {
          imageSrc ? (
            // <svg xmlns="http://www.w3.org/2000/svg">
              <img id="mainImage"
                x="0"
                y="0"
                width={1020}
                height={1020}
                src={imageSrc}

                style={{
                  // marginBottom: '-6.5px',
                  display: 'none'
                }}
                onLoad={e => {
                  hideLoader();
                  e.target.style.display = 'block';
                  setIsImageLoaded(true);
                }}
                onError={window.location.reload}

              />
            // </svg>
          ) : null
        }
      </>
    );
});


function getImageAndImageVersionFromDB(fileName){
  return new Promise((resolve) => {
    const request = indexedDB.open('ImageStore', 1);

    request.onerror = function(event) {
      getImageAndImageVersionFromDB()
      .then(result => resolve(result));
      // reject('Database error:', event.target.error);
      // console.error('Database error:', event.target.error);
    };
  
    let isNewDB = false;
    request.onupgradeneeded = function(event) {
      const db = event.target.result;
      const objectStore = db.createObjectStore('images', { keyPath: 'id' });
      isNewDB = true;
      resolve({});
    };
  
    request.onsuccess = function(event) {
      if(isNewDB) return

      const db = event.target.result;
      const transaction = db.transaction('images', 'readonly');
      const objectStore = transaction.objectStore('images');

      const getRequest = objectStore.get(fileName);
  
      getRequest.onsuccess = function(event) {
        if (!event.target.result) {
          resolve({});
        } else {
          const existingBlob = event.target.result.image;
          // Do something with the existing Blob, for example, display it
          resolve({
            imageVersion: event.target.result.imageVersion,
            imageBlob: event.target.result.image
          });
          // console.log('Image already exists in IndexedDB.');
        }
      };
  
      getRequest.onerror = function(event) {
        getImageAndImageVersionFromDB()
        .then(result => resolve(result));
      };
    };
  });
}

function setImageAndImageVersionToDB(blob, fileName, imageVersion) {
  return new Promise((resolve) => {
    setToDBFunc(blob, fileName, imageVersion).then(result => resolve(result));
  });

  function setToDBFunc(blob, fileName, imageVersion) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('ImageStore', 1);

      request.onerror = function(event) {
        setToDBFunc(blob, fileName, imageVersion)
        .then(result => resolve(result));
        // reject('Database error:', event.target.error);
        // console.error('Database error:', event.target.error);
      };
      request.onupgradeneeded = function(event) {
          const db = event.target.result;
          const objectStore = db.createObjectStore('images', { keyPath: 'id' });
      };
    
      request.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction('images', 'readwrite');
        const objectStore = transaction.objectStore('images');
  
        // const getRequest = objectStore.get(fileName);
    
        // getRequest.onsuccess = function(event) {
        //   if (!event.target.result) {
        const imageRecord = {
          id: fileName,
          image: blob,
          imageVersion
        };
        const addRequest = objectStore.put(imageRecord);

        addRequest.onsuccess = function(event) {
          resolve({
            newAdded: true
          });
          // console.log('Image saved to IndexedDB with id:', event.target.result);
        };

        addRequest.onerror = function(event) {
          setToDBFunc(blob, fileName, imageVersion)
          .then(result => resolve(result));
          // reject();
          // console.error('Error saving image to IndexedDB:', event.target.error);
        };
          // } else {
          //   const existingBlob = event.target.result.image;
          //   // Do something with the existing Blob, for example, display it
          //   const url = URL.createObjectURL(existingBlob);
          //   resolve({
          //     imageVersion: event.target.result.imageVersion,
          //     url
          //   });
          //   // console.log('Image already exists in IndexedDB.');
          // }
        // };
    
        // getRequest.onerror = function(event) {
        //   setToDBFunc(blob, fileName, imageVersion)
        //   .then(result => resolve(result));
        // };
      };
    })
  }
}