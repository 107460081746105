import { useEffect, useRef } from "react";
import SelPopapAssistent from "./SelPopapAssistent";
// import {pixel1, pixel2, pixel3, pixel4, pixel5, pixel5, pixel6, pixel7, pixel8, pixel9, pixel10, pixel11, pixel12, pixel13, pixel14, pixel15, pixel16, pixel17, pixel18, pixel19, pixel20} from './pixel';
// import canvasBg from '../canvasbg20x20.png'

import { images } from "./BuyAreaElement";

export default function SelPixelPopap({popapInfo: [leftCss, topCss, x, y, maxLength], setPreviusRectSizeRef, hidePopap, ctx}) {
    // let mainLengthRef = useRef(1); // nuyn dirqi hamar

    let previousLengthRef = useRef(null); // de skzbic klini nkarvac 1hat qarakusi, ete petk chi hashvarkel
    // let [length, setLength] = useState(1);

    useEffect(() => {
        previousLengthRef.current = 1;
    }, [x, y]);

    function resizeArea(newSize) {
        setPreviusRectSizeRef(newSize);

        if(previousLengthRef.current){
            ctx.clearRect(x * 6, y * 6, previousLengthRef.current * 6, previousLengthRef.current * 6);
            ctx.drawImage(images[previousLengthRef.current - 1], x * 6, y * 6);
        }
        previousLengthRef.current = newSize;

        ctx.fillStyle = 'blue';
        ctx.fillRect(x * 6, y * 6, newSize * 6, newSize * 6);
    }

    return (
        <div className="selPixelPopap popap" style={{left: leftCss, top: topCss}} onClick={e => e.stopPropagation()}>
            <span className="title">Select the size`</span>

            {/* <SelPopapAssistent setLength={setLength} length={length} hidePopap={hidePopap} /> */}
            <SelPopapAssistent maxLength={maxLength} x={x} y={y} resizeArea={resizeArea} hidePopap={hidePopap} />
        </div>
    );
} 