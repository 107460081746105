import defaultMedal from './defaultMedal.png';
import top3forma from './top3Forma.png';
import top2forma from './top2Forma.png';
import top1PictureForma from './top1PictureForma.png';
import crown from './crown.png';
import top2Medal from './top2Medal.png';
import top3Medal from './top3Medal.png';
import { useEffect, useRef } from 'react';
// import top1froma from './top1Forma.png';


function formatNumber(num) {
    function formatWithSuffix(value, suffix) {
        const formatted = value.toFixed(1);
        return formatted.endsWith('.0') ? Math.floor(value) + suffix : formatted + suffix;
    }

    if (num >= 1000000) {
        return formatWithSuffix(num / 1000000, ' M');
    } else if (num >= 1000) {
        return formatWithSuffix(num / 1000, ' K');
    } else {
        return num.toString();
    }
}

const imagesUrls = { // profile_id : url
    
}


export default function ProfileCont({i, profilInfo: {picture_url, length, username, media_count='-', followers_count='-', following_count='-'}}) {
    // let profil_id = username;
    
    // media_count = formatNumber(media_count);
    // followers_count = formatNumber(followers_count);
    // following_count = formatNumber(following_count);
    return (
        <>
            {///////////////  karanq optimalacnenq
                i <= 3 ? <Top3Profile i={i} length={length} picture_url={picture_url} username={username} posts={media_count} followers={followers_count} following={following_count}/> :
                <DefaultTopProfile i={i} profileInfo={{picture_url, length, username, media_count, followers_count, following_count}} />
            }
        </>
    )
}

function DefaultTopProfile({i, profileInfo : { length, picture_url, username, media_count, followers_count, following_count}}){
    // let [imageUrl, setImageUrl] = useState('');
    const imageRef = useRef();

    useEffect(() => {
        if(imagesUrls[username]) imageRef.current.src = imagesUrls[username];
        else {
            imageRef.current.onload = e => {
                imagesUrls[username] = imageRef.current.src;
            }
            imageRef.current.onerror = e => {
                fetch('/profileImage/' + username).then(async (resp) => {
                    if(resp.ok) {
                        const image_url = URL.createObjectURL(await resp.blob());
                        // setImageUrl(image_url)
                        if(!imageRef.current) return '';
                        imageRef.current.src = image_url;
                        imagesUrls[username] = image_url;
                    }else{
                        throw 'err'
                        // window.location.reload();
                    }
                })
                .catch(window.location.reload)
            }
            imageRef.current.src = picture_url;
        }
    }, [])

    return (
        <div className="profileCont default">
            <div className="image_userName">

                <img draggable="false" className="profilePicture" ref={imageRef} />
                <span className="userName" >{username}</span>
            </div>
            <div className="profileTraficContainer">
                <div>
                    <span className="val">{media_count}</span>
                    <span className="text">posts</span>
                </div>
                <div>
                    <span className="val">{followers_count}</span>
                    <span className="text">followers</span>
                </div>
                <div>
                    <span className="val">{following_count}</span>
                    <span className="text">following</span>
                </div>
            </div>    

            <div className="medalCont">
                <img draggable="false" className="medal default" src={defaultMedal} />
                <span className="medalVal" >{i}</span>
            </div>
            <span className="pixelLength" >{length} x {length}</span>
        </div>
    )
}
//////// nayev top 2 top 1
function Top3Profile({i, length, username, posts, followers, following, picture_url}) {
    // let [imageUrl, setImageUrl] = useState('');
    const imageRef = useRef();

    useEffect(() => {
        if(imagesUrls[username]) imageRef.current.src = imagesUrls[username];
        else {
            imageRef.current.onload = e => {
                imagesUrls[username] = imageRef.current.src;
            }
            imageRef.current.onerror = e => {
                fetch('/profileImage/' + username).then(async (resp) => {
                    if(resp.ok) {
                        const image_url = URL.createObjectURL(await resp.blob());
                        // setImageUrl(image_url);
                        if(!imageRef.current) return ''
                        imageRef.current.src = image_url;
                        imagesUrls[username] = image_url;
                    }else{
                        throw 'err'
                        // window.location.reload();
                    }
                }).catch(window.location.reload)
            }
            imageRef.current.src = picture_url;
        }
    }, []);

    return (
        <div className={"profileCont top top"+i}>
            <img draggable="false" className={'forma top top'+i} src={i === 3 ? top3forma : top2forma} />

            
                <div className="image_userName">
                {i === 1 ? <img draggable="false" className='crown' src={crown} /> : null}
                    {i === 1 ? <img draggable="false" className='profilePictureForma' src={top1PictureForma} /> : null}

                    <img draggable="false" className="profilePicture" ref={imageRef} />
                    <span className="userName" >{username}</span>
                </div>
                <div className="profileTraficContainer">
                    <div>
                        <span className="val">{posts}</span>
                        <span className="text">posts</span>
                    </div>
                    <div>
                        <span className="val">{followers}</span>
                        <span className="text">followers</span>
                    </div>
                    <div>
                        <span className="val">{following}</span>
                        <span className="text">following</span>
                    </div>
                </div>    

                {
                    i === 3 || i === 2? (
                        <div className="medalCont">
                            <img draggable="false" className="medal default" src={i === 2 ? top2Medal : top3Medal} />
                            <span className="medalVal" >{i}</span>
                        </div>
                    ): null
                }
                <span className="pixelLength" >{length} x {length}</span>
            
        </div>
    )
};