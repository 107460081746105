import CallersCreatorBox from "./CallersCreatorBox";
import mainLogotip from '../mainLogotip.png';
import { websiteFacebookPage, websiteInstagramPage, websiteTiktokPage } from "./domainName";
import { memo } from "react";


export default memo(function Footer() {
    
    return(
        <footer>

            <div id="footerAssistent">
            
                <img draggable="false" src={mainLogotip} />

                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                    <span style={{fontSize: '13px', marginBottom: '5px', color: 'white'}} >Follow us</span>
                    <div className="itemsCont">
                        <a href={websiteInstagramPage} className="itemCont instagram"><i className='bx bxl-instagram '></i></a>
                        <a href={websiteFacebookPage} className="itemCont facebook"><i className='bx bxl-facebook '></i></a>
                        <a href={websiteTiktokPage} className="itemCont tiktok"><i className='bx bxl-tiktok '></i></a>
                       {/* <a href={websiteYoutubePage} className="itemCont youtube"><i className='bx bxl-youtube '></i></a> */}
                    </div>

                    {/* <div id="contacts" style={{marginTop: '10px',color: 'black', fontSize: '13px', display: 'flex', flexDirection: 'column'}} >
                        <span>contacts</span>

                        <span>Gmail -- <b>the.bests.zone@gmail.com</b></span>
                        <span>Phone number -- <b>+374-98-00-82-13</b></span>
                    </div> */}
                    {/* {
                        page === 'aboutPage' ? <CallersCreatorBox /> : null
                    } */}
                </div>
            </div>
        </footer>
    )
})