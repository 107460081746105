import { memo, useCallback, useEffect, useRef, useState } from "react"

// function formatNumber(num) {
//     function formatWithSuffix(value, suffix) {
//         const formatted = value.toFixed(1);
//         return formatted.endsWith('.0') ? Math.floor(value) + suffix : formatted + suffix;
//     }

//     if (num >= 1000000) {
//         return formatWithSuffix(num / 1000000, ' M');
//     } else if (num >= 1000) {
//         return formatWithSuffix(num / 1000, ' K');
//     } else {
//         return num.toString();
//     }
// }
const InnerMainInfo = memo(function({profileInfoObj: { media_count='-', followers_count='-', following_count='-', username='---'}}) {
    const folows = useRef(0);
    folows.current++;
    console.log(folows.current, 'folows cont');

    return(
        <>
            <div className="profileTraficContainer">
                <div>
                    <span className="val">{media_count}</span>
                    <span className="text">posts</span>
                </div>
                <div>
                    <span className="val">{followers_count}</span>
                    <span className="text">followers</span>
                </div>
                <div>
                    <span className="val">{following_count}</span>
                    <span className="text">following</span>
                </div>
                
                {
                    username != '---' ? (
                        <a className="goProfileBtnCont" href={'https://www.instagram.com/'+username}>
                            {/* <button><i className='bx bx-right-arrow-alt'></i></button> */}
                            <i className='bx bx-right-arrow-alt'></i>
                        </a>
                    ) : null
                }
            </div>
        </>
    )
}, (prevProps, nextProps) => {
    if(prevProps.profileInfoObj.username !== nextProps.profileInfoObj.username)
        return false
    return true
});

const InnerImageUsername = memo(function({profileInfoObj: {username="---", picture_url=''}}) {
    const folows = useRef(0);
    folows.current++;
    console.log(folows.current, 'username image');

    const imageRef = useRef();
    useEffect(() => {
        

        // imageRef.current.onload = e => {}
        if(username != '---'){
            imageRef.current.onerror = e => {
                if(imageRef.current) imageRef.current.src = '/profileImage/'+ username;
            }
            if(imageRef.current) imageRef.current.src = picture_url;
        }else{
            if(imageRef.current) imageRef.current.src = '/profileImage/'+'dflt';
        }
            
    }, [username]);
    return (
        <>
            <div className="image_userName">
                <img ref={imageRef} width='50' height='50'/>
                <span className="userName" >{username}</span>
            </div>
        </>
    )
}, (prevProps, nextProps) => {
    if(prevProps.profileInfoObj.username !== nextProps.profileInfoObj.username)
        return false
    return true
});

const InnerBio = memo(function({bioText, changeIsBioMore}) {
    const folows = useRef(0);
    folows.current++;
    console.log(folows.current, 'bio image');

    return (
        <>
            <div className="bioCont">
                <div className="beforeEffectEl"></div>
                <span onClick={() => changeIsBioMore()} className="bio">{bioText}</span>
                {/* <span className="bio" onClick={() => setIsBioMore(!isBioMore)} > {isBioMore || biography_or_bio.length < 31 ? biography_or_bio : biography_or_bio?.slice(0, 31) + ' ...'}</span> */}
            </div>
        </>
    )
});

const MoreInfoInner = memo(function({ goToTop, hideMoreProfileInfo, profileInfoObj }) {
    const { username='---', biography_or_bio=''} = profileInfoObj;
    // const r = useRef(0);
    // r.current++;
    // console.log(r.current,'uuu');
    let [isBioMore, setIsBioMore] = useState(false);
    useEffect(() => {
        if(isBioMore) setIsBioMore(false); 
    }, [username]);

    const changeIsBioMore = useCallback(() => {
        setIsBioMore(!isBioMore)
    }, [isBioMore]);

    useEffect(() => goToTop(isBioMore), [isBioMore]);
    
    return(
        <>
            <i className='bx bx-x xBtn' onClick={hideMoreProfileInfo}></i>

            <div className="image_userNameParent">
                <InnerImageUsername profileInfoObj={profileInfoObj} />
                <InnerBio bioText={isBioMore || biography_or_bio.length < 31 ? biography_or_bio : biography_or_bio?.slice(0, 31) + ' ...'} changeIsBioMore={changeIsBioMore} />
            </div>
            <InnerMainInfo profileInfoObj={profileInfoObj} />
        </>
    )
});

export default function ProfileMoreInfo({x, y, hideMoreProfileInfo, profileInfoObj}) {
    let [coordinates, setCoordinates] = useState({x, y});
    useEffect(() => {
        setCoordinates({x, y});
        
    }, [x, y]);
    // let [mainY, setMainY] = useState(y);

    // const isElementCursoreTopRef = useRef(false);

    const mainContRef = useRef();
    useEffect(() => {
        // isElementCursoreTopRef.current = false;
        const {height, top} = mainContRef.current.getBoundingClientRect();
        if(top + height + 10 > window.innerHeight || coordinates.y + height > 1020 - 10) { 
            // isElementCursoreTopRef.current = true;
            setCoordinates({x, y: y - 50 - 50 - height, isToped: true}); 
        }
    }, [coordinates.y]);


    let goToTop = useCallback(function(isBioMore) {
        // if(isElementCursoreTopRef.current) {
            const {height, top} = mainContRef.current.getBoundingClientRect();
            if(!isBioMore) {
                if(coordinates.isToped) {
                    setCoordinates({x, y});
                }
            }else{
                if(coordinates.isToped) return setCoordinates({x, y: y - 50 - 50 - height, isToped: true})
    
                if(coordinates.y + height > 1020 - 10) {
                    // setCoordinates({x, y: y - 50 - 50 - height}); 
                    const outArea = coordinates.y + height - 1010;
                    setCoordinates({x, y: coordinates.y - outArea, isToped: true}); 
                }
            }
    }, [coordinates.y, coordinates.x]);

    return (
        <div ref={mainContRef} id="mainProfileMoreInofContainer" className="profileMoreInofContainer popap" style={{left: coordinates.x, top: coordinates.y}} onClick={e => e.stopPropagation()}>
            <MoreInfoInner goToTop={goToTop} hideMoreProfileInfo={hideMoreProfileInfo} profileInfoObj={profileInfoObj}  />
        </div>
    )
}