import { memo } from "react"

export default memo(function TitleEffect({level=1}){

    return(
        <div className="titleEffect">
            <div className="items">
                <div className={"item first active"}>1</div>
                <div className={level >= 2 ? 'item active' : 'item'}>2</div>
                <div className={level >= 3 ? 'item active third' : 'third item'}>3</div>
            </div>
            <div className="line">
                <div className={level == 3 ? 'lineInner c' : level == 2 ? 'lineInner b': 'a'}></div> 
            </div>
        </div>
    )
});