import { memo, useContext, useRef } from "react";
import { SetMessageBoxInfoContext } from "../pages/BuyArea";

export default memo(function BuyAreaCanvas({canvasRef, getOnClickInfo, setPopapInfo}) {
    // let canvasPriRef = useRef(0);
    // canvasPriRef.current++;
    // console.log(canvasPriRef.current, 'buy canvas');
    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);

    return (
        <canvas  ref={canvasRef} width="1020" height="1020" style={{ background: 'white', border: '4px ridge #aeadad' }}
            onClick={e => {
                try{
                    const [leftCss, topCss, x, y, maxLength] = getOnClickInfo(e);
                    
                    setPopapInfo([leftCss, topCss, x, y, maxLength]);
                }catch(err){
                    setMessageBoxInfo({
                        messageIconType: 'error',
                        textElement: <span className="message">This space is busy.</span>,
                        onclickFunc: () => setMessageBoxInfo(null)
                    });
                }
            }}      
        ></canvas>
    )
})