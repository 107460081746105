import { memo, useContext, useEffect, useRef, useState } from "react";
import EndBuyTransactionBox from "../components/EndBuyTransactionBox";
import { ReplaceAlertRootElement } from "../components/bigMessageCont/BigMessageCont";
import Header from "../components/Header";
import { SetMessageBoxInfoContext } from "./BuyArea";
// import Header from "../components/Header";

const Inner = memo(function() {
    const sRef = useRef(0);
    sRef.current++; console.log(sRef.current, 'uuuuuuuuu');

    const setMessageBoxInfo = useContext(SetMessageBoxInfoContext);

    let [level, setLevel] = useState(2); // [level, otherinfos]

    let xRef = useRef();
    let yRef = useRef();
    let lengthRef = useRef();
    let gmailRef = useRef();
    let passwordRef = useRef();

    // let codeRef = useRef();
    let userNameRef = useRef();
    let tokenRef = useRef();
    
    
    useEffect(() => {
        const params = (new URL(window.location)).searchParams;

        xRef.current = params.get('x');
        yRef.current = params.get('y');
        lengthRef.current = params.get('l'); 
        passwordRef.current = params.get('p'); 
        gmailRef.current = params.get('g'); 
        userNameRef.current = params.get('un'); 
        // codeRef.current = params.get('code');
        
        tokenRef.current = params.get('token');

        if(params.get('error_code')){
            return window.location.replace('/buyArea');
        }
    
        if(xRef.current && yRef.current && lengthRef.current && !passwordRef.current){
            // localStorage.removeItem('a');
            // localStorage.removeItem('b');
            // localStorage.removeItem('userName');

            setLevel({
                num: 1,
                x: xRef.current,
                y: yRef.current,
                length: lengthRef.current,
                gmail: gmailRef.current,
                userName: userNameRef.current
            });
        }else if(passwordRef.current){
            // localStorage.removeItem('a');
            // localStorage.removeItem('b');

            // const x = localStorage.getItem('x');
            // const y = localStorage.getItem('y');
            // const length = localStorage.getItem('length');
            // const userName = localStorage.getItem('userName');
            // localStorage.setItem('code', codeRef.current);
            if(!(xRef.current && yRef.current && lengthRef.current && userNameRef.current && gmailRef.current)) return window.location.replace('/buyArea');

           
            fetch('/s', { // s === second
                    method: 'POST',
                    headers: {'content-type': 'application/json'},
                    body: JSON.stringify({

                        userName: userNameRef.current, 
                        x : xRef.current,
                        y: yRef.current, 
                        length: lengthRef.current,
                        gmail: gmailRef.current,
                        password: passwordRef.current,
                        caller: localStorage.getItem('caller')
                    })
            }).then(resp => resp.json())
            .then(result => {
                
                if(result.error) {
                    setMessageBoxInfo({
                        messageIconType: 'error',
                        textElement: <span className="message">{result.message}</span>,
                        onclickFunc: () => window.location.replace(`/endBuyAreaTransaction?x=${xRef.current}&y=${yRef.current}&l=${lengthRef.current}&un=${userNameRef.current}&g=${gmailRef.current}`)
                    });
                }else if(result.isVerefied) {
                    setLevel({
                        isVerefied: true,
                        num: 3,
                        x: xRef.current,
                        y: yRef.current,
                        length: lengthRef.current
                    });
                }else{
                    // const {a, b} = result;
                    // localStorage.setItem('a', a);
                    // localStorage.setItem('b', b);
                    
                    setLevel({
                        num: 2,
                        x: xRef.current, 
                        y: yRef.current, 
                        length: lengthRef.current, 
                        userName: userNameRef.current,
                        gmail: gmailRef.current,
                        password: passwordRef.current,
                        caller: localStorage.getItem('caller')
                    });
                }
            })
            .catch(() => {
                setMessageBoxInfo({
                    messageIconType: 'error',
                    textElement: <span className="message">A problem occurred, please try again.</span>,
                    onclickFunc: () => setMessageBoxInfo(null)
                });
            })

        }else if(tokenRef.current){
            setLevel({
                num: 3,
                token: tokenRef.current,
                x: localStorage.getItem('x'),
                y: localStorage.getItem('y'),
                length: localStorage.getItem('length'),
                userName: localStorage.getItem('userName')
            });
        }
    }, []);

    return (
        <>
            {
                level ? <EndBuyTransactionBox levelInfo={level} /> : null
            }
        </>
    )
})
export default function EndBuyAreaTransaction(){
    let [messageBoxInfo, setMessageBoxInfo] = useState();


    // if(!x || !y || !length){
    //     window.location.replace('/buyArea');
    // }
    
    return (
        <>
            {/* <Header /> */}
            <ReplaceAlertRootElement messageBoxInfo={messageBoxInfo} />
            {/* <Header /> */}
            <SetMessageBoxInfoContext.Provider value={setMessageBoxInfo}>
                <Inner />
            </SetMessageBoxInfoContext.Provider>
        </>
    )
}