// import logo from './main';
// import {BrowserRouter, Routes, Route} from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from "./pages/MainPage";
// import { useCallback, useEffect, useState } from 'react';
import './App.css';
import BuyArea from "./pages/BuyArea";
import { useCallback, useRef, useState } from "react";
import EndBuyAreaTransaction from "./pages/EndBuyAreaTransaction";
import AboutProjectPage from "./pages/About";
import CallerTools from './pages/CallerToolsPage';
// import { mainDomainName } from './components/domainName';
// import ProfilsGrafica from './components/ProfilsGrafica';



function App() {

  // setInterval(() => {
  //   console.log(pixelsData_appRef.current)
  // }, 1000);

  // let [action, setAction] = useState(null);
  // const actionCreator = useCallback((newAction) => {
  //   setAction(newAction);
  // }, []);

  return (
    <div className="App">
        {/* <BrowserRouter>
          <Routes>
            <Route index element={<MainPage />}/>
            <Route path="/mainPage" element={<MainPage />}/>
            <Route path="/buyArea" element={<BuyArea />} />
            <Route path="/endBuyAreaTransaction" element={<EndBuyAreaTransaction />} />
            <Route path="/aboutProject" element={ <AboutProjectPage />} />
          </Routes>
        </BrowserRouter> */}
        <Router >
          <Routes>
            <Route index path="/mainPage" element={<MainPage />} />
            <Route path="/buyArea" element={<BuyArea />} />
            <Route path="/endBuyAreaTransaction" element={<EndBuyAreaTransaction />} />
            <Route path="/aboutProject" element={<AboutProjectPage />} />
            <Route path="*" element={<MainPage />} />
            <Route path="/callerToolsPage" element={<CallerTools />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
