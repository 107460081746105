import { memo } from "react";

export default memo(function Area({size}) { // tuyna

    let area = [];

    let pixels = [];
    for(let i = 0; i < size; i++) {
        pixels[i] = <div key={i} className="pix"></div>;
    }
    for(let i = 0; i < size; i++){
        area[i] = (
            <div key={i} className="row">
                {pixels}
            </div>
        )
    }

    return (
        <div className="areaExample">
            <div className="areaExampleAssis">
                {area}
            </div>
            <span className="areaSize">{size} x {size}</span>
        </div>
    )
})